import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';
import { ReactComponent as Warn } from './warn.svg';
import './addimages.scss';

const AddImages = () => {

  

  return (

    <Container className="addimages flex flex-col">

      <div className="text-47 font-bold text-orange">

        Добавление <br className="mobile" />изображений

      </div>

      <div className="montserrat text-23 font-semibold text-18-m mt-25 mt-20-m">

        Картинки можно загружать <br className="mobile"/>с устройства или по ссылке <br className="mobile"/>в интернете.

      </div>

      <img src="/images/addimages/main.jpg" className="addimages__main mt-35 desktop" />
      <img src="/images/addimages/main-mob.jpg" className="addimages__main mt-20-m mobile" />

      <div className="montserrat text-23 font-semibold text-18-m mt-25 mt-20-m nowrap">

        Фото занимает всю ширину <br className="mobile"/> страницы, что позволяет делать <br />впечатляющие Figma-дизайны, <br className="mobile"/>
        но об этом мы поговорим <br className="desktop"/>в <br className="mobile"/>разделе “Профессиональная <br className="mobile"/>настройка Any Link”

      </div>

      <div className="flex mt-46 desktop">

        <Warn className="desktop"/>

        <div>

          <div className="montserrat text-blue text-23 font-semibold mt-2 ml-15 desktop">
            Добавленные изображения для удобства в конструкторе занимают мало места. 
          </div>

          <div className="montserrat text-blue text-23 font-semibold mt-8 ml-15 desktop">
            На странице, которая доступна для посетителей, они заполняют всю ширину.
          </div>

        </div>

      </div>

      <img src="/images/addimages/warn.jpg" className="addimages__warn mt-10-m mobile" />

      <div className="montserrat text-blue text-16-m font-semibold mt-15 nowrap mobile">
        На странице, которая доступна для <br />посетителей, они заполняют всю <br />ширину.
      </div>

      <div className="montserrat text-44 text-28-m font-bold mt-60 mt-40-m ml-25-m">

        Рекомендации:

      </div>

      <ul className="montserrat text-23 font-semibold mt-25 desktop">

        <li className="montserrat text-23 mb-20">
          не размещай много фото друг под другом, за исключением случаев, <br />когда ты делаешь собственный Figma-дизайн
        </li>

        <li className="montserrat text-23 mb-20">используй фотографии хорошего качества и без водяных знаков</li>

        <li className="montserrat text-23 nowrap">
          если фотографии можно сгруппировать по смыслу, хорошей идеей будет <br />
          использовать интерактивный элемент “слайдер-карусель”
        </li>

      </ul>

      <ul className="montserrat text-18-m font-semibold mt-25-m mobile">

        <li className="montserrat text-18-m mb-20-m">
          не размещай много фото <br />друг под другом, кроме <br />случаев,
          когда ты делаешь собственный Figma-дизайн
        </li>

        <li className="montserrat text-18-m mb-20 mb-20-m">используй фотографии <br />хорошего качества и без <br />водяных знаков</li>

        <li className="montserrat text-18-m nowrap">
          если фотографии можно <br />сгруппировать по смыслу, <br />хорошей идеей будет <br />
          использовать интерактивный <br />элемент “слайдер-карусель”
        </li>

      </ul>

      <div className="addimages__button button_container flex flex-col-m mt-60 mt-25-m mb-15-m">

        <Button

          url= { URLS.backgroundpage }
          text= {`Назад: Настройка фона страницы`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.addslider }
          text= {`Дальше: Добавление\nслайдера-карусели`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>


    </Container>

  );

}

export default AddImages;