import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shift.scss';

const Shift = () => {

  

  return (

    <Container className="shift flex flex-col">

      <div className="text-47 font-bold text-orange">

        Как поменять местами <br className="mobile"/>элементы визитки

      </div>

      <div className="montserrat text-23 font-semibold text-16-m mt-20 mt-20-m nowrap">

        Необходимо удерживать палец <br className="mobile"/>(или левую кнопку мыши) на <br className="mobile"/>элементе, местоположение <br />
        которого мы хотим изменить, <br className="mobile"/>до тех пор, пока не создастся <br className="mobile"/>впечатление, что он “взлетел”.

      </div>

      <div className="montserrat text-23 font-semibold text-16-m mt-20 mt-20-m nowrap">

        После этого его можно двигать <br className="mobile"/>вверх или вниз, а другие элементы <br className="mobile"/>будут освобождать <br className="desktop"/>
        для него место. <br className="mobile"/><br className="mobile"/>Когда элемент окажется <br className="mobile"/>в нужном месте, можно оторвать <br className="mobile"/>палец от дисплея, <br className="desktop"/>
        или отпустить <br className="mobile"/>левую кнопку мыши. Тогда <br className="mobile"/>элемент сохранит своё <br className="mobile"/>новое местоположение.

      </div>

      <div className="montserrat text-38 font-bold text-28-m mt-60 mt-35-m nowrap">

        На что обратить <br className="mobile"/>внимание:

      </div>

      <ul className="montserrat mt-15 mt-25-m mb-100">
        
        <li className="montserrat font-semibold text-23 text-16-m relative">
          Квадратные кнопки представляют из себя группу, и их можно перетаскивать только вместе <br className="desktop"/>
          с остальными квадратными кнопками
        </li>

        <li className="montserrat font-semibold text-23 text-16-m relative">
          Если экран телефона не прокручивается при перетаскивании элемента, его можно оставить <br className="desktop"/>
          в промежуточном месте, прокрутить страницу, и затем снова начать перетаскивание
        </li>

        <li className="montserrat font-semibold text-23 text-16-m relative">
          Новые элементы всегда добавляются под всеми остальными элементами
        </li>
        
      </ul>

      <div className="button_container flex flex-col-m mt-35 shift__button">

        

        <Button

          url= { URLS.geo }
          text= {`Назад: Добавление местоположения`}
          className="blue text-24 text-center-m"

        />

      <div className="hidden" />

      </div>

      <div className="button_container flex flex-col-m mt-50-m mb-15-m mobile">

        <Button

          url= { URLS.geo }
          text= {`Назад: Добавление местоположения`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.shift }
          text= {`Дальше: Перейти в меню справочника`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default Shift;