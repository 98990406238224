import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.itemlist.scss';

const ShopItemList = () => {

  return (

    <Container className="shopitemlist flex flex-col">

      <div className="text-47 font-bold text-orange mt-15">

        Как посмотреть список своих товаров

      </div>

      <div className="flex">
        
        <img src="/images/shopitemlist/1-desktop.jpg" className="shopitemlist__first relative desktop mt-50" />

        <div className="mt-65 mt-20-m">

          <div className="montserrat text-23 text-16-m font-semibold mt-200 ml-80 mt-5-m">

            Управление товарами осуществляется в разделе <br className="mobile" />Мой магазин. 
            <br className="mobile" /><br className="mobile" /> Попасть в него можно из главного меню в личном пространстве.

          </div>

          <img src="/images/shopitemlist/1-desktop.jpg" className="shopitemlist__first relative mobile mt-25-m" />

          <div className="montserrat text-23 font-semibold mt-80 ml-80 desktop">

            Изначально раздел “Мой магазин” скрыт. <br />
            Как его активировать, мы рассказываем в главе <br />
            “Как включить функционал интернет-магазина для визитки”

          </div>

          <div className="montserrat text-16-m font-semibold mt-10-m mobile">

            Изначально раздел “Мой магазин” скрыт. <br className="desktop" />
            Как его активировать, мы <br className="mobile" />рассказываем в главе <br className="desktop" />
            “Как включить функционал <br className="mobile" />интернет-магазина для визитки”

          </div>

        </div>

      </div>

      <div className="grayline mt-5-m" />

      <div className="flex mt-30 mt-5-m">

        <div className="shopitemlist__secondtext">

          <div className="montserrat text-23 text-16-m text-right font-semibold mt-110">

            Так видит список своих товаров <br />
            владелец интернет-магазина:

          </div>

          <img src="/images/shopitemlist/2-desktop.jpg" className="shopitemlist__second relative mobile mt-20-m" />

          <div className="montserrat text-23 text-16-m text-right font-semibold mt-80 mt-20-m">

            При нажатии на товар откроется <br />
            форма редактирования товара

          </div>

          <div className="montserrat text-23 text-16-m text-right font-semibold mt-80 mt-20-m">

            В форме можно изменить данные товара, <br className="desktop" />
            а так же безвозвратно удалить товар

          </div>

        </div>

        <img src="/images/shopitemlist/2-desktop.jpg" className="shopitemlist__second relative desktop mt-20 ml-80" />

      </div>

      <div className="grayline mt-5-m" />

      <div className="flex mt-80">

        <img src="/images/shopitemlist/3-desktop.jpg" className="shopitemlist__third relative desktop mt-20 ml-80" />

        <div className="mt-350">

          <div className="montserrat text-23 text-16-m text-center font-semibold mt-50 ml-50">

            А вот так видят список товаров <br />
            посетители интернет-магазина:

          </div>

        </div>

      </div>

      <img src="/images/shopitemlist/3-desktop.jpg" className="shopitemlist__third relative mobile mt-25-m" />

      <div className="shopitemlist__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_additem }
          text= {`Назад: как добавить товар`}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_cats }
          text= {<Fragment><span className="desktop">Дальше: как управлять категориями</span><span className="mobile">Дальше: <br />как управлять категориями</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopItemList;