import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './addslider.scss';

const AddSlider = () => {

  

  return (

    <Container className="addslider flex flex-col">

      <div className="text-47 font-bold text-orange">

        Добавление <br className="mobile" />слайдера-карусели <br className="mobile" />изображений

      </div>

      <div className="montserrat text-23 font-semibold text-18-m mt-25 mt-20-m nowrap">

        Несколько изображений в <br className="mobile"/>одном! Этот элемент группирует <br className="mobile"/>добавленные фото. <br /><br className="mobile"/>
        Отображаться будет только одна <br className="mobile"/>из этих фото, но если по ней <br className="mobile"/>провести пальцем <br className="desktop"/>влево, 
        то её <br className="mobile"/>место займёт следующая <br className="mobile"/>фотография, а если вправо - <br className="mobile"/>то предыдущая.

      </div>

      <img src="/images/addslider/main.jpg" className="addslider__main mt-50 desktop" />
      <img src="/images/addslider/main-mob.jpg" className="addslider__main mt-50-m relative mobile" />

      <div className="text-44 text-23-m font-bold mt-65 mt-35-m">

        Отлично подходит для:

      </div>

      <ul className="montserrat text-23 font-semibold">

        <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">размещения скриншотов отзывов</li>
        <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">анонса мероприятия</li>
        <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">кейсов портфолио</li>
        <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">рассказа истории в картинках</li>

      </ul>

      <div className="text-38 text-20-m font-bold mt-50 mt-15-m">

        На что обратить внимание:

      </div>

      <ul className="montserrat text-23 font-semibold">

        <li className="montserrat text-23 text-17-m font-semibold mb-20 mb-15-m ml-5">
          Пока что слайдер корректно отображает только горизонтальные фото с соотношением сторон 16:9
        </li>

        <li className="montserrat text-23 text-17-m font-semibold mb-20 mb-15-m ml-5">
          На данный момент <br className="mobile"/>установлено временное ограничение 5 фото <br className="mobile"/>на один слайдер
        </li>

        <li className="montserrat text-23 text-17-m font-semibold mb-20 mb-15-m ml-5">
          Изображения должны быть качественными и без <br className="mobile"/>водяных знаков
        </li>

      </ul>

      <div className="addslider__button button_container flex flex-col-m mt-50 mt-25-m mb-15-m">

        <Button

          url= { URLS.addimages }
          text= {`Назад: Добавление изображений`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.addvideos }
          text= {`Дальше: Добавление видео`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>


    </Container>

  );

}

export default AddSlider;