import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './payment.contract.scss';

const PaymentContract = () => {

  return (

    <Container className="payment-contract flex flex-col">

      <div className="text-47 font-bold text-orange">

        Подписание договора

      </div>

      <div className="text-23 font-semibold mt-35 mt-20-m">

        После регистрации в меню ЮKassa находим вкладку ДОГОВОР. <br className="mobile" /><br className="mobile" />Выбираем раздел <br className="mobile" />“Приём платежей”. <br /><br className="mobile" />
        Появится окно “Как будете принимать платежи” <br className="mobile" />с выбором вариантов. <br className="mobile" /><br className="mobile" />Выбираем “На сайте”

      </div>

      <img src="/images/paymentcontract/1-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/1-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>
     
      <div className="text-23 font-semibold mt-30 mt-25-m">

        Введи адрес своего магазина AnyLink:

      </div>

      <img src="/images/paymentcontract/2-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/2-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        Осталось заключить договор с ЮKassa, настроить интеграцию с AnyLink и провести тестовый платёж! <br /><br className="mobile" />
        Далее мы подробно рассмотрим эти этапы.

      </div>

      <img src="/images/paymentcontract/3-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/3-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        1. Заполни банковские и налоговые реквизиты:

      </div>

      <img src="/images/paymentcontract/4-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/4-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        2. Заполни личные данные и прикрепи фото паспорта:

      </div>

      <div className="text-20 font-semibold mt-20 desktop">

        Данные должны быть указаны дословно, как в паспорте. Фото паспорта должно быть цветное, разворот <br />
        первых двух страниц (с фото). Все данные должны быть чётко видны. Формат файла: PDF, JPG, PNG.

      </div>

      <img src="/images/paymentcontract/5-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/5-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="text-12-m font-semibold mt-15-m mobile">

        Данные должны быть указаны дословно, как в паспорте. Фото паспорта должно быть цветное, разворот
        первых двух страниц (с фото). Все данные должны быть чётко видны. <br /><br />Формат файла: PDF, JPG, PNG.

      </div>

      <div className="text-23 font-semibold mt-30 mt-35-m">

        3. Формальности и проверки

      </div>

      <img src="/images/paymentcontract/6-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/6-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-50 mt-25-m">

        4. После отправки данных нужно будет подождать несколько дней, пока ЮKassa проверит их точность.

      </div>

      <img src="/images/paymentcontract/YoCheck.jpg" className="payment-contract__img__yocheck desktop"/>
      <img src="/images/paymentcontract/7-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>
      <img src="/images/paymentcontract/8-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-ul">

        5. Подписываем договор. <br className="mobile" /><br className="mobile" />Можно использовать электронную подпись, <br className="mobile" />можно подписать вручную.

      </div>

      <ul className="desktop">

        <li className="montserrat font-semibold text-20 mb-15">скачай договор на телефон или компьютер</li>
        <li className="montserrat font-semibold text-20 mb-15">внимательно прочитай условия и поставь подпись в местах своих реквизитов</li>
        <li className="montserrat font-semibold text-20 mb-15">на последней странице разборчиво: дата, подпись, печать (если есть)</li>
        <li className="montserrat font-semibold text-20 mb-15">отсканируй или чётко сфотографируй все страницы</li>
        <li className="montserrat font-semibold text-20 mb-15">загрузи последовательно каждую страницу (с помощью кнопок “Выбрать файл” и “Добавить еще файл”)</li>
        <li className="montserrat font-semibold text-20 mb-15">отправляем файлы на проверку, нажав на соответствующую кнопку</li>

      </ul>

      <img src="/images/paymentcontract/7-desktop.jpg" className="payment-contract__img mt-10 desktop"/>
      <img src="/images/paymentcontract/9-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <ul className="mobile">

        <li className="montserrat font-semibold text-20 mb-15">скачай договор на телефон <br className="mobile" />или компьютер</li>
        <li className="montserrat font-semibold text-20 mb-15">внимательно прочитай условия и поставь подпись в местах своих реквизитов</li>
        <li className="montserrat font-semibold text-20 mb-15">на последней странице разборчиво: <br className="mobile" />дата, подпись, печать (если есть)</li>
        <li className="montserrat font-semibold text-20 mb-15">отсканируй или чётко сфотографируй <br className="mobile" />все страницы</li>
        <li className="montserrat font-semibold text-20 mb-15">загрузи последовательно каждую страницу (с помощью кнопок <br className="mobile" />“Выбрать файл” и “Добавить еще файл”)</li>
        <li className="montserrat font-semibold text-20 mb-15">отправляем файлы на проверку, нажав <br className="mobile" />на соответствующую кнопку</li>

      </ul>

      <div className="text-23 font-semibold mt-30 mt-5-m">

        6. Осталось подождать, пока ЮKassa проверит документы. Но ключ API для интеграции <br /> 
      с AnyLink можно получить сразу, пока документы находятся на проверке.

      </div>

      <img src="/images/paymentcontract/8-desktop.jpg" className="payment-contract__img mt-30 desktop"/>
      <img src="/images/paymentcontract/10-mobile.jpg" className="payment-contract__img mt-25-m mobile"/>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">
        
        <Button

          url= { URLS.payment_offer }
          text= {`Назад:\nподключение платёжной системы`}
          className="blue text-24 text-center-m"

        />
      
        <Button

          url= { URLS.payment_secret }
          text= {`Дальше:\nполучение секретного ключа`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default PaymentContract;