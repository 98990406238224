import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './newclient.scss';

const NewClient = () => {

  

  return (

    <Container className="newclient">

      <div className="text-47 font-bold text-orange desktop">

        Клиент записался - что делать

      </div>

      <div className="text-47 font-bold text-orange mobile">

        Клиент записался, <br /> что делать

      </div>

      <div className="newclient__row flex flex-col-reverse-m mt-25 desktop">

        <div className="text_block mt-20">

          <div className="text-23 font-semibold mr-16-m desktop">

            Как только клиент запишется, колокольчик начнет
            <br />двигаться, и рядом с ним будет кол-во новых уведомлений

          </div>

        </div>

      </div>

      <img className="newclient__bell desktop absolute" src="/images/newclient/bell.jpg" />
      <img className="newclient__calendar mt-44 desktop" src="/images/newclient/calendar.jpg" />
      <img className="newclient__calendar mt-44 mobile" src="/images/newclient/calendar-mobile.jpg" />

      <div className="newclient__row flex flex-col-reverse-m mt-38 justify-center">

        <div className="text_block mt-18">

          <div className="text-23 font-semibold mr-16-m text-center">

            Нужно подтвердить бронирование клиента, чтобы он знал, что его ждут. <br />
            Для этого нажми на бронь

          </div>

        </div>

      </div>

      <img className="newclient__client mt-44 desktop" src="/images/newclient/client.jpg" />
      <img className="newclient__client mt-44 mobile" src="/images/newclient/client-mobile.jpg" />

      <div className="button_container newclient__button flex flex-col-m mt-150 mt-50-m mb-15-m">

        <Button

          url= { URLS.interface }
          text= {`Назад:\nзнакомство с интерфейсом`}
          className="blue text-24 text-center-m"

        />

        <Button
          url= { URLS.handleclient }
          text= {`Дальше:\nкак записать клиента вручную`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default NewClient;