import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import { ReactComponent as Warn } from './warn.svg';

import './newsite.scss';

const NewSite = () => {

  useEffect(() => {

    if ( !window?.location?.pathname ) { return; }
    let anchor = document.getElementById( window.location.pathname.split('/')[ window.location.pathname.split('/').length -1 ] )
    if ( anchor ) { anchor.scrollIntoView() }

  }, [ window?.location?.pathname ]);

  return (

    <Container className="newsite flex flex-col">

      <a className="scrollAnchor" id="create_site" />
      <div className="text-47 font-bold text-orange">

        Создание нового <br className="mobile" />Any Link

      </div>

      <div className="newsite__row flex flex-col-reverse-m mt-30">

        <div className="text_block">

          <div className="text-23 font-semibold desktop">

            Для того чтобы человек <span className="text-blue">стал твоим рефералом</span>, перейди на страницу регистрации следующим образом: <br />
            зайди на свою страницу Any Link, и нажми кнопку «Хочу свой Any Link!» в самом низу страницы.

          </div>

          <div className="text-16-m font-semibold mt-16-m mr-16-m mobile">

            Для того чтобы человек стал твоим <br /><span className="text-blue">рефералом</span>, перейди на страницу <br />регистрации следующим образом: <br />
            зайди на свою страницу Any Link, <br />и нажми кнопку «Хочу свой <br />Any Link!» в самом низу страницы.

          </div>

          <div className="text-23 font-semibold mt-40 desktop">

            Если ты создаёшь страницу <span className="text-blue">для другого человека</span>, при регистрации <span className="text-blue">вводи его(её) e-mail и номер телефона.</span> <br />
            Этот человек должен(а) будет написать тебе код, пришедший на его(её) почту при регистрации.

          </div>

          <div className="text-16-m font-semibold mt-40 mr-16-m mobile">

            Если ты создаёшь страницу для <br /><span className="text-blue">другого человека</span>, при регистрации <br />вводи его(её) e-mail и номер <br />телефона. <br />
            <br />Этот человек должен(а) будет <br />написать тебе код, пришедший на <br />его(её) почту при регистрации. <br /><br />

          </div>

          <div className="text-23 text-16-m font-semibold mt-30 mr-16-m">

            Сама регистрация предельно <br className="mobile" />проста, поэтому поговорим о <br className="mobile" />первых действиях после её <br className="mobile" />прохождения.

          </div>

        </div>

      </div>

      <a className="scrollAnchor" id="change_site_address" />
      <div className="mt-65 mt-35-m text-35 text-24-m font-bold text-orange">

        1: Измени адрес сайта

      </div>

      <div className="text-23 text-14-m font-semibold mt-22 mt-18-m mr-16-m">

        Чтобы будущим клиентам было проще <br className="mobile" />заходить на сайт, мы должны изменить 
        <br className="mobile" />автоматически <br className="desktop" /> сгенерированный при <br className="mobile" />
        регистрации уникальный номер сайта <br className="mobile" />на читаемое слово. <br /><br className="mobile" />
        По этой ссылке страница будет доступна <br className="mobile" />в интернете.

      </div>

      <div className="newsite__warn flex items-center mt-36 mt-24-m">

        <Warn />

        <div className="montserrat text-23 text-14-m font-semibold text-blue">

          Адрес сайта <br className="mobile" /><span className="text-red">не должен содержать пробелов, <br className="mobile" /><span className="text-red-blue">только английские буквы.</span></span><br />
          <span className="desktop">Также в адресе можно использовать  точку и нижнее подчёркивание.</span>

        </div>

      </div>

      <div className="montserrat text-15-m font-semibold text-blue mt-15-m mobile">

        Также в адресе можно использовать <br className="mobileт" />точку и нижнее подчёркивание.

      </div>

      <div className="montserrat font-bold text-28 text-24-m mt-50 text-central">Адресом сайта <br className="mobile" />может быть:</div>

      <div className="montserrat font-bold text-23 mt-26 mt-35-m">Желательно:</div>

      <ul className="mt-15-m">

        <li className="montserrat font-semibold text-23 text-14-m">ник в истаграме/тиктоке</li>
        <li className="montserrat font-semibold text-23 text-14-m">название компании</li>
        <li className="montserrat font-semibold text-23 text-14-m">псевдоним</li>
        <li className="montserrat font-semibold text-23 text-14-m">фамилия и имя <span className="text-gray__dark">(например <br className="mobile" />фамилия_имя или фамилия.имя)</span></li>

      </ul>

      <div className="montserrat font-bold text-23 mt-26 mt-15-m">Нежелательно, но лучше, <br className="mobile" />чем случайный номер:</div>

      <ul><li className="montserrat font-semibold text-23 text-14-m">email <span className="text-gray__dark">(то, что перед @)</span></li></ul>

      <div className="montserrat font-bold text-28 text-24-m mt-30 mt-30-m">Действия для <br className="mobile" />смены адреса сайта:</div>
 
      <img className="newsite__changeaddress mt-35 desktop" src="/images/newsite/changeaddress.jpg" />
      <img className="newsite__change mt-23-m mobile" src="/images/newsite/change-1-mob.jpg" />
      <img className="newsite__change mt-23-m mobile" src="/images/newsite/change-2-mob.jpg" />
      <img className="newsite__change mt-23-m mobile" src="/images/newsite/change-3-mob.jpg" />

      <div className="montserrat font-semibold text-23 text-17-m mt-26 mt-25-m">

        После третьего шага появится <br className="mobile" />поле для ввода, туда напиши <br className="mobile" />
        новый адрес, и нажми <br className="mobile" />“сохранить”.

      </div>

      <a className="scrollAnchor" id="meet_site" />
      <div className="mt-65 mt-35-m text-35 text-24-m font-bold text-orange">

        2. Познакомься <br className="mobile" />с конструктором <br className="mobile" />интернет-визитки

      </div>

      <div className="montserrat font-semibold text-23 text-17-m mt-22 mt-16-m mb-46 mb-250-m">
        Нажми “Моя визитка” в личном <br className="mobile" />пространстве. Знакомься - это <br className="mobile" />
        конструктор страницы.</div>

      <div className="relative">
        
        <img className="newsite__constructor mt-35 desktop" src="/images/newsite/constructor.jpg" />

        <div className="newsite__constructor__info absolute">

          <div className="montserrat font-bold text-blue text-35 text-22-m mb-15-m">Немного о конструкторе:</div>

          <div className="montserrat font-semibold text-22 desktop">

            <p>Можно добавить сколько угодно любых доступных элементов<br /></p>
            <p>Новые элементы добавляются вниз страницы<br /></p>
            <p>Элементы можно редактировать - для этого нужно <br />
            кликнуть по добавленному элементу<br /></p>
            <p>Элементы можно менять местами - для этого нужно нажать на элемент,<br />
            и держать палец на нём, не двигая до тех пор, пока он не “взлетит”.<br />
            После этого его можно перемещать вверх и вниз, другие элементы<br />
            будут освобождать для него место<br /></p>
            <p>Добавленные изображения для удобства в конструкторе <br />
            занимают мало места. На реальной странице они заполняют всю ширину<br /></p>
            <p>На способах связи цифра - счётчик кликов. Его видно только в конструкторе.<br /></p>

          </div>

          <ul className="montserrat font-semibold text-16-m mobile">

            <li>Можно добавить сколько угодно <br />любых доступных элементов<br /><br /></li>
            <li>Новые элементы добавляются <br />вниз страницы<br /><br /></li>
            <li>Элементы можно редактировать - <br />для этого нужно кликнуть по <br />
            добавленному элементу<br /><br /></li>
            <li>Элементы можно менять местами - <br />для этого нужно нажать на элемент,<br />
            и держать палец на нём, не двигая <br />до тех пор, пока он не “взлетит”.<br />
            После этого его можно перемещать <br />вверх и вниз, другие элементы<br />
            будут освобождать для него место<br /><br /></li>
            <li>Добавленные изображения <br />для удобства в конструкторе <br />
            занимают мало места. <br />На реальной странице <br />они заполняют всю ширину<br /><br /></li>
            <li>На способах связи цифра - <br />счётчик кликов. Его видно <br />только в конструкторе.<br /><br /></li>

          </ul>

        </div>

      </div>

      <img className="newsite__info mt-15-m mobile" src="/images/newsite/info.jpg" />

      <div className="newsite__ol mt-35-m mobile">

        <p className="montserrat font-semibold text-16-m">1. Сменить фон страницы <br />или шапки<br /></p>
        <p className="montserrat font-semibold text-16-m">2. Посмотреть, как посетители <br />будут видеть страницу<br /></p>
        <p className="montserrat font-semibold text-16-m">3. Изменить аватарку страницы, <br />если её нет - загрузить новую<br /></p>
        <p className="montserrat font-semibold text-16-m">4. Добавить кнопку мессенджера, <br />ссылку на сайт, кнопку <br />социальной сети</p>
        <p className="montserrat font-semibold text-16-m">5. Добавить заголовок, абзац или <br />бегущую строку<br /></p>
        <p className="montserrat font-semibold text-16-m">6. Добавить текст раскрывающийся <br />по клику на его заголовок,<br /> 
        или слайдер-карусель (несколько <br />фото, которые можно листать)<br /></p>
        <p className="montserrat font-semibold text-16-m">7. Загрузить изображение или <br />вставить его по ссылке, 
        либо <br />добавить видео с YouTube<br /></p>
        <p className="montserrat font-semibold text-16-m">8. Добавить карту, на которой <br />будет отмечен указанный адрес<br /></p>
        <p className="montserrat font-semibold text-16-m">9. Об этом пункте мы поговорим <br />в разделе “настройка интернет-<br />магазина”<br /></p>
        <p className="montserrat font-semibold text-16-m">10. Об этом пункте мы поговорим <br />в разделе “настройка онлайн-<br />записи”<br /></p>

      </div>

      <a className="scrollAnchor" id="avatar_site" />
      <div className="mt-65 mt-35-m text-35 text-24-m font-bold text-orange">

        3. Загрузи аватарку

      </div>

      <div className="montserrat font-semibold text-23 text-17-m mt-22 mt-16-m mb-46 mb-20-m">
        Аватарка должна быть скачана <br className="mobile" />на телефон или компьютер, 
        с <br className="mobile" />которого ты настраиваешь <br className="mobile" />страницу</div>

      <img className="newsite__avatar mt-15-m mobile" src="/images/newsite/avatar-mob.jpg" />

      <div className="flex">

        <img className="newsite__avatar mt-35 mr-52 desktop" src="/images/newsite/avatar.jpg" />

        <div>

          <div>

            <div className="montserrat font-bold text-28 text-22-m mt-26 mt-15-m">Аватаркой страницы <br className="mobile" />может быть:</div>

            <ul className="newsite__ul">

              <li className="montserrat font-semibold text-23 text-17-m">логотип компании / бизнеса</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">фотография владельца <br className="mobile" />страницы</li>

            </ul>

          </div>
          
          <div>

          <div className="montserrat font-bold text-28 text-22-m mt-26 mt-25-m">Рекомендации:</div>

            <ul className="newsite__ul">

              <li className="montserrat font-semibold text-23 text-17-m">фото должно быть <br className="mobile" />качественным, но не <br className="mobile" />сильно большим по весу<br /><br /></li>
              <li className="montserrat font-semibold text-23 text-17-m">
                главный объект на фото <br className="mobile" />должен быть расположен <br className="mobile" />крупно и по центру. <br className="desktop"/>
                Можно <br className="mobile" />обрезать фото в графическом <br className="mobile" />редакторе или средствами <br />
                редактора фото в телефоне, <br className="mobile" />сделать квадратным, тогда <br className="mobile" />центр квадрата 
                <br className="desktop" /> и будет <br className="mobile" />центром аватарки<br /><br /></li>
              <li className="montserrat font-semibold text-23 text-17-m">фото должно вызывать <br className="mobile" />доверие</li>

            </ul>

          </div>

        </div>

      </div>

      <a className="scrollAnchor" id="short_site_name" />
      <div className="mt-65 mt-35-m text-35 text-24-m font-bold text-orange">

        4. Добавь название <br className="mobile" />и краткое описание

      </div>

      <div className="montserrat font-semibold text-23 text-17-m mt-22 mt-16-m mb-46 mb-20-m">Чтобы добавить название, <br className="mobile" />воспользуемся инструментом <br className="mobile" />“добавить текст или бегущую <br className="mobile" />строку”:</div>

      <img className="newsite__text-mob mr-16-m mobile" src="/images/newsite/text-mob.jpg" />

      <div className="flex">

        <img className="newsite__text mt-35 mr-52 desktop" src="/images/newsite/text.jpg" />

        <div>

          <div className="mt-50 mt-25-m">

          <div className="montserrat font-bold text-28 text-22-m mt-26 mt-15-m">Названием страницы <br className="mobile" />может быть:</div>

            <ul className="newsite__ul">

              <li className="montserrat font-semibold text-23 text-17-m">название компании / бизнеса</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">псевдоним <br className="mobile" />владельца страницы</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">имя и/или фамилия <br className="mobile" />владельца страницы</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">имя и специализация <br className="mobile" />владельца страницы</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">адрес страницы или ник <br className="mobile" />в инстаграме/тиктоке</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">название оказываемой услуги</li><br className="mobile" />

            </ul>

          </div>

          <div className="mt-25-m mobile">

            <div className="montserrat font-bold text-28 text-22-m mt-26 mt-15-m">Рекомендации:</div>

              <ul className="newsite__ul">

                <li className="montserrat font-semibold text-23 text-17-m">в названии максимум<br />
                одно-два слова</li><br />
                <li className="montserrat font-semibold text-23 text-17-m">для названия используем <br />заголовок, по центру</li><br />
                <li className="montserrat font-semibold text-23 text-17-m">нельзя использовать яркие, <br />грязные и кислотные цвета <br />вместо этого можно вообще <br />не менять цвет текста</li><br />

              </ul>

            </div>

          </div>

      </div>

      <div className="flex flex-row-reverse mt-88 justify-start desktop">

        <img className="newsite__title mt-35 mr-250 ml-90" src="/images/newsite/title.jpg" />

        <div className="newsite__text-reverse mt-130 desktop">

          <div className="montserrat font-bold text-28 text-right">Рекомендации:</div>

          <div className="flex justify-end items-center mt-35"><div className="newsite__point" /><p className="montserrat font-semibold text-23">в названии максимум одно-два слова</p></div>
          <div className="flex justify-end items-center"><div className="newsite__point" /><p className="montserrat font-semibold text-23">для названия используем заголовок, по центру</p></div>
          <div className="flex justify-end items-center"><div className="newsite__point newsite__point__minus" /><p className="montserrat font-semibold text-23 nowrap">нельзя использовать яркие, грязные и кислотные цвета <br /><span className="ml-69">вместо этого можно вообще не менять цвет текста</span></p></div>

        </div>

      </div>

      <div className="montserrat font-semibold text-23 text-17-m mt-64 mb-22">Чтобы добавить описание,<br className="mobile" /><span className="text-blue">еще раз </span> 
      воспользуемся инструментом “добавить <br className="mobile" />текст или бегущую строку”:</div>

      <img className="newsite__subtitle mt-25-m mobile" src="/images/newsite/subtitle-mob.jpg" />

      <div className="flex">

        <img className="newsite__subtitle mr-65 mt-35 desktop" src="/images/newsite/subtitle.jpg" />

        <div>

          <div className="mt-35">

            <div className="montserrat font-bold text-28 text-22-m mt-26 mt-26-m">Кратким описанием страницы может быть:</div>

            <ul className="newsite__ul">

              <li className="montserrat font-semibold text-23 text-17-m">девиз компании / бизнеса</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">пара предложений <br className="mobile" />о владельце страницы</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">предложение связаться <br className="mobile" />используя один из <br className="mobile" />способов связи</li><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">уникальное <br className="mobile" />торговое предложение</li>

            </ul>

          </div>

          <div className="mt-35 mt-1-m">

            <div className="montserrat font-bold text-28 text-22-m mt-26 mt-25-m">Рекомендации:</div>

            <ul className="newsite__ul">

              <li className="montserrat font-semibold text-23 text-17-m">для краткого описания <br className="mobile" />используем обычный текст, <br className="mobile" />по центру</li><br /><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">два-три предложения в <br className="mobile" />кратком описании, их можно разделять <br className="desktop" />переносами строк <br className="mobile" />(но не всегда нужно, действуй <br className="mobile" />по ситуации)</li><br /><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">если нужно больше текста - <br className="mobile" />лучше добавить два отдельных <br className="mobile" />текстовых <br className="desktop" />элемента, чем <br className="mobile" />пихать всю информацию <br className="mobile" />в один</li><br /><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">если в кратком описании есть <br className="mobile" />перечисление - для каждого <br className="mobile" />пункта <br className="desktop" />добавлять отдельный <br className="mobile" />текстовый элемент (в этом <br className="mobile" />случае выравнивание <br />по левому или правому краю). <br className="mobile" />Будет красиво смотреться, <br className="mobile" />если перед <br className="desktop" />текстом каждого <br className="mobile" />такого пункта поставить <br className="mobile" />соответствующий смыслу <br />текста смайлик Emoji</li><br /><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">приветствуется умеренное <br className="mobile" />использование смайлов Emoji, <br />они оживляют страницу</li><br /><br className="mobile" />
              <li className="montserrat font-semibold text-23 text-17-m">нельзя использовать яркие, <br className="mobile" />грязные и кислотные цвета <br />вместо этого можно вообще <br className="mobile" />не менять цвет текста</li><br /><br className="mobile" />

            </ul>

          </div>

        </div>

      </div>

      <div className="mt-80 mt-20-m text-39 text-24-m font-bold text-central text-central__lefter">

        На что обратить <br className="mobile" />внимание:

      </div>

      <div className="text-23 text-16-m font-semibold mt-35 mt-30-m mr-16-m">

        Иногда слова из которых мы <br className="mobile" />составили описание не смотрятся <br className="mobile" />рядом друг с другом. <br /><br className="mobile" />
        Это мы понимаем визуально, <br className="mobile" />после того, как уже добавили <br className="mobile" />текстовый элемент на страницу. <br />
        Полезно изменять ширину <br className="mobile" />страницы (с компьютера) и <br className="mobile" />смотреть, как изменяется <br className="mobile" />отображение текста. <br />
        <br />
        Если мы заметили, что текст не <br className="mobile" />смотрится на какой либо ширине <br className="mobile" />экрана, либо вовсе <br className="desktop"/>
        в любом, стоит <br className="mobile" />подумать, может нужно где-то <br className="mobile" />добавить переносы строки. <br />
        <br />
        Если это не помогло - какими <br className="mobile" />словами можно заменить <br className="mobile" />существующие слова, <br className="desktop" />
        или может <br className="mobile" />стоит поменять порядок слов, для <br className="mobile" />того, чтобы текст смотрелся приятно. <br />

      </div>

      <div className="mt-50 mt-20-m text-42 text-36-m font-bold text-gray__casper text-central text-central__leftest">

        Примеры:

      </div>

      <div className="montserrat text-30-m text-green text-center font-bold mt-30-m text-central__leftest mobile">Хорошо</div>
      <div className="montserrat text-20-m text-center font-bold mt-10-m text-central__leftest mobile">Легко читается</div>
      <img className="newsite__exam mt-15-m mobile" src="/images/newsite/exam-1-mob.jpg" />

      <div className="montserrat text-30-m text-purple text-center font-bold mt-30-m text-central__leftest mobile">Плохо!</div>
      <div className="montserrat text-20-m text-center font-bold mt-10-m text-central__leftest mobile">Нечитабельно</div>
      <img className="newsite__exam mt-15-m mobile" src="/images/newsite/exam-2-mob.jpg" />

      <div className="montserrat text-30-m text-purple text-center font-bold mt-30-m text-central__leftest mobile">Тоже плохо!</div>
      <div className="montserrat text-20-m text-center font-bold mt-10-m text-central__leftest mobile">Каша из текста</div>
      <img className="newsite__exam mt-15-m mobile" src="/images/newsite/exam-3-mob.jpg" />

      <img className="newsite__examples mt-44 desktop" src="/images/newsite/examples.jpg" />

      <div className="newsite__button button_container flex flex-col-m mt-80 mb-35-m mb-25-m">

        <Button

          url= { URLS.contentplan }
          text= {`Назад:\nСоздание контент-плана страницы`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.site_urls }
          text= {`Дальше: Добавление способов связи`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default NewSite;