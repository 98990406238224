import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import { ReactComponent as Warn } from './icons/warn.svg';

import './links.scss';

const Links = () => {

  

  return (

    <Container className="links flex flex-col">

      <div className="text-47 font-bold text-orange">

        Добавление <br className="mobile" />способов связи <br className="mobile" />и кнопок соцсетей

      </div>

      <div className="links__row flex flex-col-reverse-m mt-40">

        <div className="text_block">

          <div className="text-23 text-16-m font-semibold mr-16-m">

            Кнопки способов связи <br className="mobile" />удобны тем, что в один клик посетитель страницы связывается с владельцем.

          </div>

          <div className="text-23 text-16-m font-semibold mt-40 mr-16-m">

            Например, при клике <br className="mobile" />посетителя на “телефон” открывается приложение телефона, 
            и в нём уже вставлен номер владельца страницы, даже если его нет в списке контактов! 
            Осталось только “позвонить” нажать.<br className="mobile" /><br className="mobile" />

          </div>

          <div className="text-23 text-16-m font-semibold mt-30 mr-16-m">

            Или другой пример: при клике посетителя на “WhatsApp” откроется приложение WhatsApp, 
            <br className="desktop" /> и в нём сразу чат с владельцем страницы, да да, даже если его нет в списке контактов!

          </div>

        </div>

      </div>

      <div className="montserrat text-38 text-28-m font-bold mt-65 mt-35-m mob-center">

        Кнопки бывают <br className="mobile" />двух видов:

      </div>

      <div className="links__buttons flex">

        <div className="links__buttons__quarter flex flex-col items-center mr-35">

          <p className="montserrat text-30 text-30-m font-bold mt-40 mt-35-m">Квадратные</p>

          <img src="/images/links/www-short.png" className="links__buttons__www-short mt-18 mt-2-m" />

          <ul className="mt-20-m">

            <li className="montserrat text-23 text-18-m font-semibold">располагаются рядом <br className="mobile"/>друг с другом</li>
            <li className="montserrat text-23 text-18-m font-semibold">можно разместить <br className="mobile"/>только в блоке <br />квадратных кнопок</li>
            <li className="montserrat text-23 text-18-m font-semibold">в блок можно добавить сколько <br className="desktop"/>угодно <br className="mobile"/>квадратных кнопок</li>
            <li className="montserrat text-23 text-18-m font-semibold">блок квадратных кнопок можно <br className="desktop"/>перемещать, но...</li>
            <li className="montserrat text-23 text-18-m font-semibold">больше одного блока квадратных <br className="desktop"/>кнопок на странице быть не может</li>
            <li className="montserrat text-23 text-18-m font-semibold">нельзя написать текст <br className="mobile"/>для кнопки</li>

          </ul>

          <p className="montserrat text-30 text-30-m font-bold mt-40 mt-25-m ml-30-m">Когда использовать?</p>

          <p className="montserrat text-23 font-semibold mt-25 desktop">

            Размещаем основные способы связи<br />
            с владельцем страницы, для которых<br /> 
            не нужен текст кнопки:

          </p>

          <p className="montserrat text-20-m font-semibold mt-5-m ml-30-m mobile">

            Размещаем способы связи с владельцем страницы, для которых не нужен текст кнопки:

          </p>

          <img src="/images/links/sm.png" className="links__buttons__sm mt-18" />

        </div>

        <div className="links__buttons__quarter flex flex-col items-center mr-40">

        <p className="montserrat text-30 text-30-m font-bold mt-40 mt-35-m">Стандартные</p>

          <img src="/images/links/www-long.png" className="links__buttons__www-long mt-18 mt-2-m" />

          <ul className="mt-75">

            <li className="montserrat text-23 text-18-m font-semibold">располагаются друг <br className="mobile"/>под другом</li>
            <li className="montserrat text-23 text-18-m font-semibold">можно написать текст <br className="mobile"/>для кнопки</li>
            <li className="montserrat text-23 text-18-m font-semibold">можно разместить в произвольных <br className="desktop"/>местах <br className="mobile"/>на странице</li>
            <li className="montserrat text-23 font-semibold">
              можно добавить несколько кнопок <br />для одного и того же способа связи<br />
              <span className="text-gray__casper montserrat text-23 text-18-m font-semibold">(например, несколько телефонов <br className="desktop"/>для разных номеров)</span>
            </li>

          </ul>

          <p className="montserrat text-30 text-30-m font-bold mt-40 mt-25-m ml-30-m">Когда использовать?</p>

          <p className="montserrat text-23 font-semibold mt-25 desktop">

            Когда нам важно задать кнопке текст. <br />
            Например, у нас два ютуб-канала, и мы<br /> 
            хотим разместить ссылки на оба:

          </p>

          <p className="montserrat text-20-m font-semibold mt-5-m ml-30-m mobile">

          Когда нам важно <br />задать кнопке текст. 
            Например, у нас два ютуб-канала, и мы<br /> 
            хотим разместить ссылки на оба:

          </p>

          <img src="/images/links/sm-links.png" className="links__buttons__sm-links mt-18" />

        </div>

      </div>

      <div className="flex mt-50 mt-1-m">

        <Warn /><p className="montserrat text-blue font-semibold text-23 warntext desktop">
          Ты можешь использовать любой вариант кнопок, какой сочтёшь нужным, или оба варианта<br />
          одновременно. Не пиши слишком много текста в стандартную кнопку.
        </p>

        <p className="montserrat text-blue font-bold text-14-m warntext mobile">
          Ты можешь использовать <br />любой вариант кнопок, <br /> какой сочтёшь нужным, <br />или оба варианта сразу.
        </p>

      </div>

      <p className="montserrat text-blue font-bold text-18-m mobile mt-5-m">
          Не пиши слишком много текста в стандартную кнопку.
      </p>

      <p className="montserrat font-semibold text-18-m mobile mt-20-m">

       Чтобы добавить способ связи, <br />
        воспользуемся инструментом <br />
        “добавить контакт или ссылку” <br /><br />
        За один раз добавляется одна <br />
        кнопка, их можно добавить <br />
        сколько угодно:

      </p>

      <img src="/images/links/main.jpg" className="links__main mt-50 desktop" />
      <img src="/images/links/main-mobile.jpg" className="links__main mt-1-m mobile" />

      <div className="mt-15-m mobile">
        
        <p className="montserrat links-ol font-bold text-21-m mt-20-m">1. Группа <br /> <span className="ml-20-m">квадратных контактов</span></p>
        <p className="montserrat links-ol__point font-semibold text-19-m ml-20-m mb-16-m">здесь ты можешь <br /> изменить вид кнопки</p>

        <p className="montserrat links-ol font-bold text-21-m mt-20-m">2. Укажи куда <br /> <span className="ml-20-m">будет вести кнопка</span></p>
        <p className="montserrat links-ol__point font-semibold text-19-m ml-20-m mb-16-m">всегда читай внимательно <br /> что здесь написано</p>

        <p className="montserrat links-ol font-bold text-21-m mt-20-m">3. Нажми на иконку</p>
        <p className="montserrat links-ol__point font-semibold text-19-m ml-20-m mb-16-m">и напиши текст который<br /> будет написан на кнопке</p>

        <p className="montserrat links-ol font-bold text-21-m mt-20-m">4. Можно изменить <br /> <span className="ml-20-m">цвет текста и фона</span></p>
        <p className="montserrat links-ol__point font-semibold text-19-m ml-20-m mb-16-m">но не всегда нужно</p>

      </div> 

      <div className="montserrat text-38 text-28-m font-bold mt-80 mt-35-m">

        Добавь способы связи и если есть соцсети - кнопки соцсетей

      </div>

      <div className="flex mt-75 mt-35-m">

        <Warn /><p className="montserrat text-blue font-semibold text-20 warntext desktop">
          Не нужно добавлять стандартную кнопку, если уже добавлена квадратная кнопка с тем же адресом!<br />
          Верно и обратное: если добавлена стандартная кнопка и ее использование оправдано - квадратный аналог ни к чему!
        </p>

        <p className="montserrat text-blue font-bold text-14-m warntext mobile">
          Не нужно добавлять <br />стандартную кнопку, если <br />уже добавлена квадратная <br />кнопка с тем же адресом!
        </p>

      </div>

      <p className="montserrat text-blue font-bold text-18-m mobile mt-5-m">
      Верно и обратное: если <br />добавлена стандартная кнопка <br />и ее использование оправдано <br /> - квадратный аналог ни к чему!
      </p>

      <div className="links__button button_container flex flex-col-m mt-80 mb-15-m">

        <Button

          url= { URLS.create_site }
          text= {`Назад: Создание нового Any Link`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.background }
          text= {`Дальше: Настройка фона шапки`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default Links;