import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import { ReactComponent as Warn } from './icons/warn.svg';

import './background.page.scss';

const BackgroundPage = () => {

  

  return (

    <Container className="backgroundpage flex flex-col">

      <div className="text-47 font-bold text-orange">

        Подбираем изображение <br className="mobile" />для фона страницы

      </div>

      <div className="backgroundpage__warntext flex mt-35 mt-5-m mb-25">

        <Warn className="desktop" />

        <div>

          <div className="backgroundpage__warntext__up montserrat text-blue text-20 font-semibold desktop">
            Внимание! Сначала прочитай “подбираем изображение для фона шапки” 
          </div>

          <img src="/images/backgroundpage/warn.jpg" className="backgroundpage__warn mt-20-m mobile" />

          <div className="backgroundpage__warntext__down montserrat text-blue text-20 font-semibold desktop">
            так как в этом разделе описано как попасть <br className="mobile" />в режим изменения фона! 
          </div>

          <div className="backgroundpage__warntext__down montserrat text-blue text-16-m mt-16-m font-semibold mobile">
            так как в нем описано как попасть <br className="mobile" />в режим изменения фона! 
          </div>

        </div>

      </div>

      <div className="backgroundpage__row flex flex-col-reverse-m mt-15-m">

        <div className="text_block">

          <div className="text-23 font-semibold desktop">

            Фон страницы подобрать сложнее всего. Он <span className="text-red__deep">не должен быть слишком ярким и пёстрым</span>,
            потому что <br />на странице главное - это контент страницы. Фон не должен перебивать его, и слишком отвлекать.<br /><br />

          </div>

          <div className="text-16-m font-semibold mr-16-m nowrap mobile">

            Фон страницы подобрать сложнее <br />всего. Он не должен быть слишком <br /><span className="text-red__deep font-bold">ярким и пёстрым</span>,
            потому что <br />на странице главное - это контент <br /> страницы. Фон не должен <br />перебивать его, и слишком <br />отвлекать.<br /><br />

          </div>

          <div className="text-23 text-16-m font-semibold mr-16-m">

            Идеально для фона страницы <br className="mobile" />подходят "бесшовные паттерны". <br className="mobile" />Если такие изображения <br className="mobile" />
            расположить <br className="desktop" />рядом друг с другом, <br className="mobile" /><span className="text-orange text-bold-mob">то не будет видно никаких стыков <br className="mobile" /></span><span className="text-orange text-orange-desk">между ними</span>.<br /><br />

          </div>

          <div className="text-23 text-16-m font-semibold mr-16-m nowrap">

            Поэтому наша цель - найти <br className="mobile" />бесшовный паттерн в цветовой <br className="mobile" />гамме и тематике подходящей <br className="mobile" />нашей странице.

          </div>

        </div>

      </div>

      <img src="/images/backgroundpage/google.jpg" className="backgroundpage__google mt-35 desktop" />
      <img src="/images/backgroundpage/google-mob.jpg" className="backgroundpage__google mt-15-m mobile" />

      <div className="text-23 text-15-m font-semibold mt-33 mt-15-m mr-16-m">

        В поисковой запрос можно <br className="mobile" />добавить необходимый цвет и <br className="mobile" />тематику, но иногда это не то что <br className="mobile" />поможет, <br className="desktop" />
        а даже может помешать. <br className="mobile" />На поиск хорошего изображения <br className="mobile" />может уйти немало времени.

      </div>

      <div className="text-32 montserrat text-24-m font-bold mt-50 mt-25-m">

        Примеры <br className="mobile" />бесшовных паттернов:

      </div>

      <div className="inline">
        
        <div className="text-23 text-14-m font-medium font-medium-mob mt-25 mt-15-m mr-16-m">

          Нам нужен ненавязчивый узор. Если <br className="mobile" />узор подходит, но он слишком яркий, <br className="mobile" />можно в графическом <br className="desktop" />редакторе <br className="mobile" />
          изменить прозрачность картинки <br className="mobile" />на 50% или даже 10-20-30%, после чего <br className="mobile" />попробовать <br className="desktop" />установить её как фон <br className="mobile" />страницы. 
          <span className="desktop-desk"> Ниже примеры хороших паттернов для фона страницы:</span>

        </div>

      </div>

      <div className="montserrat text-15-m font-bold mob-cen mob-cen__lefter mt-25-m mobile">Ниже примеры хороших паттернов <br />для фона страницы:</div>
      
      <img src="/images/backgroundpage/examples.jpg" className="backgroundpage__eval backgroundpage__examples mt-60 relative desktop" />

      <img src="/images/backgroundpage/example-one.jpg" className="backgroundpage__example mt-30-m mobile" />
      <img src="/images/backgroundpage/example-two.jpg" className="backgroundpage__example mt-40-m mobile" />
      <img src="/images/backgroundpage/example-three.jpg" className="backgroundpage__example mt-40-m mobile" />

      <div className="text-32 montserrat text-28-m font-bold mt-50 mt-30-m">

        Как “примерить” фон из поисковика

      </div>

      <div className="text-23 text-16-m font-semibold mt-30 mt-15-m mr-16-m">

        Этот процесс подробно описан в <br className="mobile" />разделе “Подбираем изображение <br className="mobile" />для фона шапки”, <br className="desktop" />единственное <br className="mobile" />
        отличие - что вместо шапки мы <br className="mobile" />выделяем страницу.

      </div>

      <div className="text-38 montserrat text-28-m font-bold mob-cen mob-cen__lefter mt-50 mt-25-m">

        На что обратить внимание:

      </div>

      <ul className="montserrat text-23 text-17-m mt-35 mt-20-m font-semibold">

        <li>На изображении не должно быть водяных знаков</li>
        <li>Изображение должно быть качественным - хорошо отображаться и на компьютерной версии</li>
        <li>Изображение должно подходить по тематическому смыслу страницы</li>

      </ul>

      <div className="backgroundpage__button button_container flex flex-col-m mt-80 mt-5-m mb-15-m">

        <Button

          url= { URLS.background }
          text= {`Назад: Настройка фона шапки`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.textpage }
          text= {`Дальше: Наполнение страницы текстом`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default BackgroundPage;