import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.open.scss';

const ShopOpen = () => {

  return (

    <Container className="shopopen flex flex-col">

      <div className="text-47 font-bold text-orange">

        Как включить <br className="mobile" />функционал <br className="mobile" />интернет-магазина

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-30 mt-20-m">

        Изначально раздел <br className="mobile" />интернет-магазина скрыт. <br className="mobile" /><br className="mobile" />Чтобы активировать его функционал, <br className="desktop" />
        нужно добавить первый товар. Это можно сделать <br className="mobile" />в конструкторе интернет-визитки:

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-30 mt-30-m mobile">

        1. В Личном просторанстве выбери раздел "моя визитка"

      </div>

      <div className="flex mt-60">

        <img src="/images/shopopen/1-desktop.jpg" className="shopopen__first relative desktop" />
        <img src="/images/shopopen/1-mobile.jpg" className="shopopen__first relative mt-15-m mobile" />
        <img src="/images/shopopen/2-desktop.jpg" className="shopopen__second relative desktop ml-80" />

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-30-m mobile">

        2.  В разделе “Моя визитка” выбери <br />
        пункт “Товар в интернет-магазин”

      </div>

      <img src="/images/shopopen/2-mobile.jpg" className="shopopen__second relative mt-25-m mobile" />

      <div className="flex mt-80 mt-5-m">

        <div className="montserrat text-23 font-semibold fontbottom desktop">

          Откроется краткое знакомство с каталогом товаров.<br />
          После прочтения текста на каждом слайде, нажимай “Далее”

        </div>

        <div className="montserrat text-16-m font-semibold mt-30-m mobile">

          3. Откроется краткое знакомство <br />с каталогом товаров.<br /><br />
          После прочтения текста на <br />каждом слайде, нажимай “Далее”

        </div>

        <img src="/images/shopopen/3-desktop.jpg" className="shopopen__third relative desktop ml-50" />

      </div>

      <img src="/images/shopopen/3-desktop.jpg" className="shopopen__third relative mt-20-m mobile" />

      <div className="flex mt-140">

        <img src="/images/shopopen/4-desktop.jpg" className="shopopen__fourth relative desktop ml-50" />

        <div className="mt-150 ml-60 mt-5-m">

          <div className="montserrat text-23 text-16-m font-semibold mt-30">

            После краткого знакомства откроется раздел управления <br />
            интернет-магазином, где можно будет добавить первый товар, <br />
            чтобы активировать интернет-магазин.

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-150">

            Когда интернет-магазин будет активирован, в раздел управления <br />
            интернет-магазином можно будет попасть из главного меню  <br />
            личного пространства.

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-150">

            Как правильно добавить товар - отдельная тема, <br className="desktop" />
            её можно изучить в следующей главе.

          </div>

        </div>

      </div>

      <div className="shopopen__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_sales }
          text= {<Fragment><span className="desktop">Назад: как продавать товары через Any Link</span> <span className="mobile">Назад: <br />как продавать товары</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_additem }
          text= {<Fragment><span className="desktop">Дальше: как добавить товар</span> <span className="mobile">Дальше: <br />как добавить товар</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopOpen;