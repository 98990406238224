import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './addvideos.scss';

const Addvideos = () => {

  

  return (

    <Container className="addvideos flex flex-col">

      <div className="text-47 font-bold text-orange">

        Добавление видео

      </div>

      <div className="montserrat text-23 font-semibold text-18-m mt-25 mt-20-m nowrap">

        На данный момент можно <br className="mobile"/>добавить видео, если оно <br className="mobile"/>загружено на YouTube. <br className="desktop"/>
        Для <br className="mobile"/>этого нужно скопировать <br className="mobile"/>его YouTube-ссылку, и вставить <br className="mobile"/>её при добавлении элемента.

      </div>

      <div className="montserrat text-23 font-semibold text-18-m mt-25 mt-20-m nowrap">

        На странице появится <br className="mobile"/>проигрыватель, нажав на <br className="mobile"/>который можно сразу же начать  <br />
        воспроизведение, без перехода <br className="mobile"/>со страницы на YouTube!<br className="mobile"/>

      </div>

      <img src="/images/addvideos/main.jpg" className="addvideos__main mt-50 desktop" />
      <img src="/images/addvideos/main-mob.jpg" className="addvideos__main mt-20-m relative mobile" />

      <div className="text-18-m font-semibold mt-5-m mobile">

        Если ссылка корректная, <br />
        появится предпросмотр видео:

      </div>

      <div className="flex-shift justify-between items-start mt-100 mt-5-m">

        <div>

          <div className="text-44 text-23-m font-bold mt-30-m left">

            Отлично подходит для:

          </div>

          <ul className="montserrat text-23 font-semibold">

            <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">приветственного видео <br className="mobile"/>или интервью</li>
            <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">видео кейсов портфолио</li>
            <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">видео с мероприятия</li>
            <li className="montserrat text-23 text-18-m font-semibold mb-20 mb-10-m ml-5">видео отзывов</li>

          </ul>

        </div>

        <div>

          <div className="text-44 text-23-m font-bold mt-30-m right">

            Рекомендации:

          </div>

          <ul className="montserrat text-23 font-semibold">

            <li className="montserrat text-23 text-17-m font-semibold mb-20 mb-15-m ml-5">
              слишком длинное видео <br className="mobile"/>будет лишним
            </li>

            <li className="montserrat text-23 text-17-m font-semibold mb-20 mb-15-m ml-5">
              хорошо, если у видео есть <br className="mobile"/>дизайн заставки
            </li>

            <li className="montserrat text-23 text-17-m font-semibold mb-20 mb-15-m ml-5">
              лучше без видео, чем с некачественным видео
            </li>

          </ul>

        </div>

      </div>

      <div className="addvideos__button button_container flex flex-col-m mt-60 mt-25-m mb-15-m">

        <Button

          url= { URLS.addslider }
          text= {`Назад: Добавление\nслайдера-карусели`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.faq }
          text= {`Дальше: Добавление ответов\nна частые вопросы`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>


    </Container>

  );

}

export default Addvideos;