import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './background.scss';

const Background = () => {

  

  return (

    <Container className="background flex flex-col">

      <div className="text-47 font-bold text-orange">

        Выбор заднего фона <br className="mobile" />страницы и шапки

      </div>

      <div className="background__row flex flex-col-reverse-m mt-40">

        <div className="text_block desktop">

          <div className="text-23 text-16-m font-semibold mr-16-m">

            Выбор хорошего и подходящего <br className="mobile" />фона для шапки и страницы <br className="mobile" />
            играет важную роль в её внешнем <br className="mobile" />виде<br className="desktop" />

          </div>

          <div className="text-23 text-16-m font-semibold mr-16-m">

            Перейти в режим настройки <br className="mobile" />
            фона можно, кликнув на эту кнопку:

          </div>

        </div>

        <div className="text-16-m font-semibold mr-16-m mobile">

          Выбор хорошего и подходящего <br />фона для шапки и страницы <br  />
          играет важную роль в её <br />внешнем виде. <br /><br />Перейти в режим настройки фона можно, кликнув на эту кнопку:

        </div>

      </div>

      <img src="/images/background/header.jpg" className="background__header mt-35 desktop" />
      <img src="/images/background/header-mobile.jpg" className="background__header mt-20-m mobile" />

      <div className="montserrat text-38 text-28-m font-bold mt-46 mt-20-m">

        Важно знать:

      </div>

      <div className="text-23 text-15-m font-semibold mt-35 mt-15-m mr-16-m">

        На версии сайта для компьютеров <br className="mobile" />шапка автоматически размещается <br className="mobile" />
        ЗА страницей, <br className="desktop" />и заполняет всё <br className="mobile" />видимое пространство. <br className="mobile" />
        Изображение должно быть <br className="mobile" />качественным, и будет пушка!

      </div>

      <div className="background__eval flex justify-between mt-50 mt-20-m">

        <div className="text-40 text-24-m text-left text-gray__casper font-bold nowrap z">

          Мобильная <br className="desktop"/>версия

        </div>

        <img src="/images/background/eval-mob.jpg" className="background__eval__z background__eval-mob relative mobile" />

        <div className="text-40 text-24-m text-right text-gray__casper font-bold mt-10-m z">

          Компьютерная <br />версия

        </div>

        <img src="/images/background/eval-desk.jpg" className="background__eval__z background__eval-desk relative mobile" />

      </div>

      <img src="/images/background/eval.jpg" className="background__eval background__eval__z relative desktop" />

      <div className="text-47 font-bold text-orange z">

        Побдираем изображение <br className="mobile" />для фона шапки

      </div>

      <div className="text-23 text-16-m font-semibold mt-40 mb-20-m mr-16-m z">

        Если будущий владелец страницы <br className="mobile" />предоставил качественное фото, <br className="mobile" />
        связанное с его бизнесом, <br className="mobile" />и подходящее <br className="desktop" />для использования <br className="mobile" />
        в шапке, то мы просто загрузим это фото <span className="text-gray__casper">(так и проверим, подходит ли оно для шапки)</span>

      </div>

      <img src="/images/background/header-image.jpg" className="background__header-image relative desktop" />
      <img src="/images/background/header-image-head-mob.jpg" className="background__header-image background__header-image-head relative mobile" />

      <div className="montserrat text-20-m mt-10-m font-semibold flex mobile">

        <p className="mr-15-m">1. </p><p>Нажми на шапку, чтобы <br /> выбрать её</p>

      </div>

      <div className="montserrat text-20-m font-semibold flex mobile mt-minus">

        <p className="mr-15-m">2.</p><p>Нажми на “фото”, чтобы <br />перейти к загрузке <br />фотографии</p>

      </div>

      <img src="/images/background/header-image-body-mob.jpg" className="background__header-image background__header-image-body relative mobile" />

      <div className="montserrat text-20-m mt-10-m font-semibold flex mobile">

        <p className="mr-15-m">3. </p><p>Нажми “загрузите фото”, <br />и выбери изображение</p>

      </div>

      <p className="ml-33-m text-18-m font-semibold text-gray__casper text-subtext">оно должно быть предварительно загружено <br className="mobile"/>на телефон/компьютер, с которого ты настраиваешь страницу</p>

      <div className="montserrat text-20-m font-semibold flex mobile mt-minus mt-5-m">

        <p className="mr-15-m">4.</p>
        
        <p>
          
          Нажимай на кнопки масштаба, и остановись <br className="mobile"/>на варианте, который <br className="mobile"/>лучше отображает <br className="mobile"/>картинку <br className="mobile"/>
          <span className="text-18-m font-semibold text-gray__casper"> ( чаще всего это 1 )</span>

        </p>

      </div>

      <div className="montserrat text-20-m font-semibold flex mobile mt-minus">

        <p className="mr-15-m">5.</p><p>Нажми на зеленую <br className="mobile"/>галочку, <br className="desktop"/>чтобы <br className="mobile"/>применить</p>

      </div>

      <div className="text-23 text-18-m font-semibold mr-16-m if-msg mob-cen">

        Если фото для шапки <span className="text-purple__deep">заранее <br className="mobile" />не было</span>, или предложенный <br className="mobile" />
        вариант <span className="text-purple__deep">не подошёл</span>, <br className="desktop" />придется <span className="text-orange font-bold">поискать в интернете</span>. <br className="mobile" /><br className="mobile" />
        <span className="text-17-m">Нам нужно качественное фото,  <br className="mobile" />подходящее по тематике страницы.</span>

      </div>

      <p className="montserrat mt-25-m text-16-m text-gray__light font-semibold mob-cen mob-cen__lefter mobile">Кликай в поисковике на пункты, <br />отмеченные красными цифрами</p>

      <img src="/images/background/google-first-mob.jpg" className="background__google-first mt-1-m mobile" />

      <p className="montserrat mt-20-m text-22-m text-gray__light font-semibold mob-cen mob-cen__lefter mobile">Выбирай изображение <br />высокого качества</p>

      <p className="montserrat text-15-m text-subtext__small text-gray__light font-semibold mob-cen mob-cen__lefter mobile">
        
        я в текущем примере рассматриваю <br />страницу дизайнера, <br />
        поэтому написал “абстрактный дизайн”<br />
      
      </p>

      <p className="montserrat text-15-m text-subtext__small text-gray__light font-semibold mob-cen mob-cen__lefter mobile">
        
        в твоём случае может быть другой <br />поисковый запрос
      
      </p>

      <img src="/images/background/google.jpg" className="background__google desktop" />
      <img src="/images/background/google-second-mob.jpg" className="background__google-second mobile" />

      <div className="montserrat text-24-m font-bold mt-35-m mobile">

        Чтобы проверить, <br className="mobile" /> подходит изображение <br className="mobile" />или нет:

      </div>

      <div className="montserrat text-20-m font-semibold mt-5-m flex mobile">

        <p className="mr-15-m">1.</p><p>Выбери понравившееся <br />изображение</p>

      </div>

      <img src="/images/background/check-first-mob.jpg" className="background__check-first mobile" />

      <div className="montserrat text-20-m font-semibold mt-5-m flex mobile">

        <p className="mr-15-m">2.</p><p>Копируй адрес картинки</p>

      </div>

      <img src="/images/background/check-second-mob.jpg" className="background__check-second mobile" />

      <div className="montserrat text-20-m font-semibold mt-5-m flex mobile">

        <p className="mr-15-m">3.</p><p>Вставь ссылку, и <br />изображение в шапке!</p>

      </div>

      <img src="/images/background/check-third-mob.jpg" className="background__check-third mobile" />

      <div className="mr-16-m mt-25-m text-18-m font-semibold mobile">

        Делай так, пока не найдёшь <br />подходящее изображение. <br />
        Потом нажми на зелёную  <br />галочку, чтобы применить  <br />изменения. <br /> <br />
        И обязательно проверь, как  <br />это выглядит на версии для  <br />компьютеров! 

      </div>

      <div className="mr-16-m mt-25-m text-16-m text-blue font-semibold mobile">

        Если изображение найдено, <br />вместо “скопировать URL” нажми “Сохранить картинку как” 
        и сохрани фон к себе на устройство. <br /><br />Затем загрузи его с устройства <br />в Any Link - 
        если этого не сделать, <br />из интернета изображение может исчезнуть, и тогда исчезнет и <br />с дизайна. 

      </div>

      <img src="/images/background/check.jpg" className="background__check desktop" />

      <div className="text-38 montserrat text-28-m font-bold mob-cen mob-cen__lefter mt-10 mt-25-m">

        На что обратить внимание:

      </div>

      <ul className="montserrat text-23 text-17-m mt-35 mt-20-m font-semibold">

        <li>На изображении не должно быть водяных знаков</li>
        <li>Изображение должно быть качественным - хорошо отображаться и на компьютерной версии</li>
        <li>Изображение должно подходить по тематическому смыслу страницы</li>

      </ul>

      <div className="background__button button_container flex flex-col-m mt-80 mt-25-m mb-15-m">

        <Button

          url= { URLS.site_urls}
          text= {`Назад: Добавление способов связи`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.backgroundpage }
          text= {`Дальше: Настройка фона страницы`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default Background;