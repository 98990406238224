import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.cats.scss';

const ShopCats = () => {

  return (

    <Container className="shopcats flex flex-col">

      <div className="text-47 font-bold text-orange mt-35">

        Как управлять категориями

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-60 mt-25-m">

        Добавить новую категорию можно прямо во время добавления товара. <br />
        <br className="mobile" />Но как переименовать или удалить категорию?

      </div>

      <div className="flex">

        <img src="/images/shopcats/1-desktop.jpg" className="shopcats__first relative desktop mt-60 mt-5-m" />

        <div className="montserrat text-23 text-16-m font-semibold mt-250 mt-20-m ml-80">

          В разделе мой магазин нужно <br />
          нажать кнопку “сортировка товаров”

        </div>

      </div>

      <img src="/images/shopcats/1-desktop.jpg" className="shopcats__first relative mobile mt-25-m" />

      <div className="flex">

        <div>

          <div className="montserrat text-23 text-16-m font-semibold mt-120">

            Затем нажать “все категории”:

            <img src="/images/shopcats/1-mobile.jpg" className="shopcats__third relative mobile mt-25-m" />

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-220">

            И выбрать из списка категорию, <br />
            которую хочешь переименовать <br />
            или удалить:

            <img src="/images/shopcats/2-mobile.jpg" className="shopcats__fourth relative mobile mt-30-m" />
          
          </div>

        </div>

        <img src="/images/shopcats/2-desktop.jpg" className="shopcats__second relative desktop mt-60" />

      </div>

      <div className="flex mt-80">

        <img src="/images/shopcats/3-desktop.jpg" className="shopcats__third relative desktop mt-60" />

        <div>

          <div className="montserrat text-23 text-16-m font-semibold mt-60 ml-88 mt-minus">

            Если нажать на карандаш справа от названия <br className="desktop" />
            категории - можно отредактировать название

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-44 mt-25-m ml-88">

            А если нажать на красный крестик, <br />
            категория будет удалена

          </div>

          <img src="/images/shopcats/3-desktop.jpg" className="shopcats__third relative mobile mt-25-m" />

          <div className="montserrat text-23 text-16-m font-semibold mt-44 ml-88">

            Также в этом режиме отображаются только те товары, <br className="desktop" />
            которые прикреплены  <br className="mobile" />к выбранной категории

          </div>

        </div>

      </div>
            
      <div className="shopcats__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_itemlist }
          text= {<Fragment><span className="desktop">Назад: как посмотреть список товаров</span>
          <span className="mobile">Назад: <br />как посмотреть список товаров</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_itemquestions }
          text= {<Fragment><span className="desktop">Дальше: вопросы о товарах от покупателей</span>
          <span className="mobile">Дальше: <br />вопросы о товарах от покупателей</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopCats;