import React from 'react';
import './container.scss';

const Container = ({ children, className = "" }) => {

  return(

    <div className={`${ className }`}>

      { children }
      
    </div>

  );

}

export default Container;