import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.itemquestions.scss';

const ShopItemQuestions = () => {

  return (

    <Container className="shopitemquestions flex flex-col">

      <div className="text-47 font-bold text-orange mt-35">

        Вопросы о товарах <br className="mobile" />от покупателей

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-60 mt-25-m">

        Прежде чем купить товар, покупатель может задать <br className="mobile" />какой либо вопрос о нём. <br /><br className="mobile" />
        Вместе с вопросом он оставляет свои контактные данные.

      </div>

      <div className="flex">

        <img src="/images/shopitemquestions/1-desktop.jpg" className="shopitemquestions__first relative desktop mt-60" />

        <div>

          <div className="montserrat text-23 text-16-m font-semibold mt-140 mt-20-m ml-60">

            Когда покупатель задал вопрос, <br />
            продавцу приходит уведомление.

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-65 ml-60 mt-25-m">

            Колокольчик начинает качаться, <br className="mobile" />и рядом <br className="desktop" />
            с ним располагается счётчик новых уведомлений

          </div>

        </div>

      </div>
      
      <img src="/images/shopitemquestions/1-desktop.jpg" className="shopitemquestions__first relative mobile mt-25-m" />

      <div className="flex mt-150 mt-20-m">

        <img src="/images/shopitemquestions/2-desktop.jpg" className="shopitemquestions__second relative desktop" />

        <div className="montserrat text-23 text-16-m font-semibold mt-80 ml-60 mt-minus">

          Если нажать на колокольчик, откроются уведомления. <br /><br className="mobile" />
          Там можно увидеть текст вопроса <br className="mobile" />о товаре. <br className="desktop" />
          Нажми “подробнее”

        </div>

      </div>

      <img src="/images/shopitemquestions/2-desktop.jpg" className="shopitemquestions__second relative mobile mt-30-m" />

      <div className="flex mt-140 mt-minus">

        <div className="mt-150 ml-30">

          <div className="montserrat text-23 text-16-m font-semibold">

            Откроется вопрос о товаре: <br className="mobile" />здесь видно сам <br className="desktop" />
            товар, вопрос <br className="mobile" />от покупателя, а также кнопки <br />
            связи с покупателем

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-350 mt-25-m">

            Нажми на удобный способ связи, чтобы <br className="desktop" />
            пообщаться с покупателем. <br className="mobile" /><br className="mobile" />
            После того как <br className="desktop" />
            вопрос будет решён, его можно удалить, <br className="desktop" />
            нажав на красную кнопку “архивировать <br className="desktop" />  
            (скрыть) вопрос”

          </div>

        </div>

        <img src="/images/shopitemquestions/3-desktop.jpg" className="shopitemquestions__third relative desktop mt-60" />

      </div>

      <img src="/images/shopitemquestions/3-desktop.jpg" className="shopitemquestions__third relative mobile mt-30-m" />

      <div className="flex mt-150 ml-30">

        <img src="/images/shopitemquestions/4-desktop.jpg" className="shopitemquestions__fourth relative desktop" />

        <div className="montserrat text-23 text-16-m font-semibold mt-60 ml-80">
        
          Также в раздел вопросов о товарах можно попасть, <br className="desktop" />
          нажав на нижней панели кнопку с изображением конверта

        </div>

      </div>
            
      <img src="/images/shopitemquestions/4-desktop.jpg" className="shopitemquestions__fourth relative mobile mt-25-m" />

      <div className="shopitemquestions__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_cats }
          text= {<Fragment><span className="desktop">Назад: как управлять категориями</span><span className="mobile">Назад: <br className="mobile" />как управлять категориями</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_boughts }
          text= {<Fragment><span className="desktop">Дальше: как узнать, что твой товар купили</span><span className="mobile">Дальше: <br className="mobile" />как узнать, что твой товар купили</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopItemQuestions;