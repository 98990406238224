import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './payment.prepay.scss';

const PaymentPrepay = () => {

  return (

    <Container className="payment-prepay flex flex-col">

      <div className="text-47 font-bold text-orange">

        Включение предоплаты <br className="mobile" />для услуги

      </div>

      <div className="text-23 font-semibold mt-35 mt-20-m">

        После подписания договора <br className="mobile" />с ЮKassa и получения секретного ключа возвращаемся в AnyLink.
      
      </div>

      <img src="/images/paymentprepay/1-desktop.jpg" className="payment-prepay__img mt-30 mt-25-m"/>

      <div className="flex mt-5">

        <div className="text-23 font-semibold mt-200 mt-15-m">

          В календаре заходим в настройки органайзера <br />
          и жмем кнопку <br className="mobile" />“Ввести API-ключи”

        </div>

        <img src="/images/paymentprepay/2-desktop.jpg" className="payment-prepay__img payment-prepay__img__sec mt-30 mt-25-m relative"/>

      </div>
      
      <div className="flex mt-minus">

        <div className="text-23 font-semibold mobile">

          Здесь нам понадобятся <span className="font-bold">ShopID</span> и <span className="font-bold">SecretKey</span>. <br /><br />

          <span className="font-bold">SecretKey</span> - это секретный ключ, сохранённый ранее <br />
          (смотри предыдущий раздел)

        </div>

        <img src="/images/paymentprepay/3-desktop.jpg" className="payment-prepay__img mt-30 mt-25-m relative"/>

        <div className="text-23 font-semibold mt-150 ml-150 desktop">

          Здесь нам понадобятся <span className="font-bold">ShopID</span> и <span className="font-bold">SecretKey</span>. <br /><br />

          <span className="font-bold">SecretKey</span> - это секретный ключ, <br />сохраненный ранее <br />
          (смотри предыдущий раздел)

        </div>

      </div>

      <div className="text-23 font-semibold mt-50 mt-25-m">

        А вот чтобы получить <span className="font-bold">ShopID</span>, нужно вернуться в ЮKassa, <br className="mobile" />в меню выбрать вкладку 
        НАСТРОЙКИ и раздел “Магазин”. <img src="/images/paymentprepay/YoCa-mobile.jpg" className="payment-prepay__img mt-25-m mobile"/>
        <br className="mobile" />В настройках магазина копируем номер shopId

      </div>

      <img src="/images/paymentprepay/YoCa-desktop.jpg" className="payment-prepay__img__yoca mt-30 desktop"/>
      <img src="/images/paymentprepay/YoCa-mobile-sec.jpg" className="payment-prepay__img mt-25-m mobile"/>

      <div className="flex mt-100">

        <img src="/images/paymentprepay/4-desktop.jpg" className="payment-prepay__img"/><img src="/images/paymentprepay/4-desktop.jpg" className="payment-prepay__img desktop"/>

        <div className="text-23 font-semibold mt-150 ml-150">

          После ввода ShopId и SecretKey <br />
          жми кнопку “Сохранить”

        </div>

        <img src="/images/paymentprepay/4-desktop.jpg" className="payment-prepay__img mt-25-m mobile"/>

      </div>

      <div className="flex mt-35">

        <div className="text-23 font-semibold mt-150">

          В появившемся окне жми кнопку <br className="desktop" />
          “Одобрение получено” <br />
          (если предыдущие шаги готовы)

        </div>

        <img src="/images/paymentprepay/5-desktop.jpg" className="payment-prepay__img ml-250 mt-25-m"/>

      </div>

      <div className="flex mt-35 mt-1-m">

        <div className="text-23 font-semibold mt-150 ml-150 mb-25-m mobile">

          Осталось только включить онлайн-кассу

        </div>

        <img src="/images/paymentprepay/kassa-off-desk.jpg" className="payment-prepay__img payment-prepay__img__off"/>

        <div className="text-23 font-semibold mt-150 ml-150 desktop">

          Осталось только включить онлайн-кассу

        </div>

      </div>

      <div className="flex mt-35 mt-5-m">

        <div className="text-23 font-semibold mt-150 mt-20-m mb-25-m ">

          Готово, можно начинать делать бабки!

        </div>

        <img src="/images/paymentprepay/kassa-on-desk.jpg" className="payment-prepay__img  ml-250"/>

      </div>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">

        <Button

          url= { URLS.payment_secret }
          text= {`Назад:\nполучение секретного ключа`}
          className="blue text-24 text-center-m"

        />
      
        <div className="hidden desktop" />

      </div>

    </Container>

  );

}

export default PaymentPrepay;