import React, { useState } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';

const Accordeon = ({ head, children, className = "", defaultOpened = false }) => {

  const [ isOpened, setIsOpened ] = useState( defaultOpened );
  function toggle() { setIsOpened( !isOpened ) };


  return (

    <div className={`accordeon ${ cssIf( isOpened, "accordeon--opened" ) } ${ className } pointer`}>

      <div className="accordeon-head" onClick={() => { toggle() }}>

        { head }

      </div>

      { isOpened && <div className="accordeon-content">{ children } </div> }

    </div>

  );

}
 
export default Accordeon;