import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './faq.scss';

const Faq = () => {

  

  return (

    <Container className="faq flex flex-col">

      <div className="text-47 font-bold text-orange">

        Добавление ответов <br className="mobile"/>на частые вопросы

      </div>

      <div className="montserrat text-23 font-semibold text-16-m mt-25 mt-20-m nowrap">

        Элемент “аккордеон” полезен, <br className="mobile"/>когда текста много, и его можно <br className="mobile"/>сгруппировать по смыслу.

      </div>

      <div className="montserrat text-23 font-semibold text-16-m mt-25 mt-20-m nowrap">

        Вот как его добавить:

      </div>

      <img src="/images/faq/main.jpg" className="faq__main mt-35 desktop" />
      <img src="/images/faq/main-mob.jpg" className="faq__main mt-20-m relative mobile" />

      <div className="montserrat text-23 font-semibold text-16-m mt-69 mt-35-m nowrap">

        Заголовок не должен содержать <br className="mobile"/>много текста, но при этом должен <br className="mobile"/>сообщать о том, что именно <br />
        находится в развёрнутом тексте. <br className="mobile"/><br className="mobile"/>Развёртнутый текст отображается <br className="mobile"/>при клике на заголовок. <br /><br />
        Можно добавить сколько угодно <br className="mobile"/>аккордеонов. <br className="mobile"/><br className="mobile"/>На каждый вопрос с ответом - <br className="mobile"/>по одному элементу.

      </div>

      <div className="faq__button button_container flex flex-col-m mt-80 mt-50-m mb-15-m">

        <Button

          url= { URLS.addvideos }
          text= {`Назад: Добавление видео`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.geo }
          text= {`Дальше: Добавление местоположения`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default Faq;