const preparing = "/preparing";
const settingup = "/settingup";
const prepayment = "/prepayment";
const howtochedule = "/howtochedule";

const URLS = {

  preparing: preparing,

  landinfo: `${ settingup }/landinfo`,
  contentplan: `${ settingup }/contentplan`,
  create_site: `${ settingup }/create_site`,
  change_site_address: `${ settingup }/change_site_address`,
  meet_site: `${ settingup }/meet_site`,
  avatar_site: `${ settingup }/avatar_site`,
  short_site_name: `${ settingup }/short_site_name`,
  site_urls: `${ settingup }/links`,
  background: `${ settingup }/background`,
  backgroundpage: `${ settingup }/backgroundpage`,
  textpage: `${ settingup }/textpage`,
  addimages: `${ settingup }/addimages`,
  addslider: `${ settingup }/addslider`,
  addvideos: `${ settingup }/addvideos`,
  faq: `${ settingup }/faq`,
  geo: `${ settingup }/geo`,
  shift: `${ settingup }/shift`,

  howtostart: `${ settingup }/howtostart`,
  addingservice: `${ settingup }/addingservice`,
  setchedule: `${ settingup }/setchedule`,
  interface: `${ settingup }/interface`,
  newclient: `${ settingup }/newclient`,
  handleclient: `${ settingup }/handleclient`,
  timegap: `${ settingup }/timegap`,

  shop_sales: `${ settingup }/shop_sales`,
  shop_open: `${ settingup }/shop_open`,
  shop_additem: `${ settingup }/shop_additem`,
  shop_itemlist: `${ settingup }/shop_itemlist`,
  shop_cats: `${ settingup }/shop_cats`,
  shop_itemquestions: `${ settingup }/shop_itemquestions`,
  shop_boughts: `${ settingup }/shop_boughts`,
  shop_saleshystory: `${ settingup }/shop_saleshystory`,
  shop_aftersales: `${ settingup }/shop_aftersales`,
  shop_aftersalesfix: `${ settingup }/shop_aftersalesfix`,

  payment_offer: `${ settingup }/payment_offer`,
  payment_contract: `${ settingup }/payment_contract`,
  payment_secret: `${ settingup }/payment_secret`,
  payment_prepay: `${ settingup }/payment_prepay`,

}

export default URLS;