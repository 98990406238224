import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './interface.scss';

const Interface = () => {

  

  return (

    <Container className="interface">

      <div className="text-47 font-bold text-orange">

        Знакомство <br className="mobile" /> с интерфейсом

      </div>

      <div className="interface__row flex flex-col-reverse-m mt-38">

        <div className="text_block mt-18">

          <div className="text-23 font-semibold mr-16-m">

            При попадании в раздел “онлайн-запись” ты увидишь свой график:

          </div>

        </div>

      </div>

      <img className="interface__main mt-88 desktop" src="/images/interface/main.jpg" />
      <img className="interface__main mt-88 mobile" src="/images/interface/main-mobile.jpg" />

      <div className="interface__row flex flex-col-reverse-m mt-38">

        <div className="text_block mt-28">

          <div className="text-23 font-semibold desktop">

            Обед можно отключить для отдельного дня, выбрав день, нажав на обед, и нажав соответствующую кнопку

          </div>

        </div>

      </div>

      <div className="button_container flex flex-col-m mt-80 mt-15-m mb-15-m">

        <Button

          url= { URLS.setchedule }
          text= {`Назад:\nнастройка рабочего графика`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.newclient}
          text= {`Дальше:\nклиент записался - что делать`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default Interface;