import React, { Fragment, useEffect } from 'react';
import { ReactComponent as Warn } from '../../../images/warnicon.svg';

import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './landinfo.scss';

const Landinfo = () => {

  

  return (

    <Container className="landinfo">

      <div className="text-47 font-bold text-orange">

        Какая информация <br className="mobile" />потребуется для <br className="mobile" />настройки?

      </div>

      <div className="landinfo__row flex flex-col-reverse-m">

        <div className="text_block mt-18">

          <div className="text-22 font-semibold mr-16-m mt-25-m">

            Для качественной настройки <br className="mobile" />необходимо собрать как можно <br className="mobile" />больше информации! 
            <br className="mobile" /><br className="mobile" /> Какой - читай ниже:

          </div>

        </div>

      </div>

      <div className="flex mt-36 mt-12-m">

        <Warn className="landinfo__warn relative" />

        <div className="text_block">

          <div className="text-20 font-semibold text-blue mt-15-m ml-16-m">

            Владелец в контексте <br className="mobile" />руководства - будущий <br className="mobile" />владелец страницы. <br />
            <span className="desktop">Это ты, если создаешь страницу себе, или твой клиент, если настраиваешь другому человеку.</span>

          </div>

        </div>
      
      </div>

      <div className="text-14 font-semibold text-blue mt-30-m mobile">

        Это ты, если создаешь страницу себе,  <br className="mobile" />или твой клиент, если настраиваешь  <br className="mobile" />другому человеку.

      </div>

      <div className="landinfo__textblock landinfo__textblock_purple">

        <div className="text_block">

          <div className="text-24 font-semibold text-purple">

            Обязательные данные:
        
          </div>

          <ul className="text-22 font-semibold mt-33">

            <li>E-MAIL владельца страницы</li>
            <li>Номер телефона владельца страницы</li>
            <li>Название компании или имя владельца</li>
            <li>Логотип или фотография владельца <span className="li">для аватарки</span>
            <span className="text-gray__notabene mobile">(для аватарки)</span></li>
            <li>Краткая информация о компании или <br className="mobile" />человеке</li>
            <li>Все способы связи с компанией или <br className="mobile" />человеком <span className="text-gray__notabene">(мессенджеры)</span></li>
            <li>Ссылки на соцсети компании или <br className="mobile" />человека <span className="text-gray__notabene">(если есть)</span></li>
            <li>Пожелания по цветовой гамме <br className="mobile" />страницы <span className="text-gray__notabene">(2-3 основных цвета)</span></li>
            <li>Желаемый адрес страницы <br className="mobile" /><span className="text-gray__notabene">(это может быть ник в инстаграме <span className="mobile mt-minus" />или название компании)</span></li>

          </ul>

        </div>

      </div>

      <div className="landinfo__textblock landinfo__textblock_yellow">

        <div className="text_block">

          <div className="landinfo__textblock__text_yellow text-24 font-semibold">

            Желательно также <br className="mobile" />получить что-то <br className="mobile" />из этого:
        
          </div>

          <ul className="text-22 font-semibold mt-33">

            <li>3-5 пунктов почему должны выбрать <br className="mobile" />именно владельца страницы <br />
            <span className="text-gray__notabene"> (конкурентные преимущества)</span></li>
            <li>Фотографии и/или видео о <br className="mobile" />деятельности владельца страницы</li>
            <li>Частые вопросы и ответы на них <br className="mobile" />
            <span className="text-gray__notabene"> (если применимо к виду деятельности)</span></li>
            <li>Отзывы от клиентов в виде <br className="mobile" />скриншотов или видео</li>
            <li>Если страница посвящена <br className="mobile" />мероприятию - перечень <br className="mobile" />событий мероприятия</li>
            <li>Если к владельцу страницы люди <br className="mobile" />приходят в офис или на дом - адрес</li>

          </ul>

        </div>

      </div>

      <div className="button_container landinfo__button flex flex-col-m mt-64 mt-50-m mb-25-m">

        <div className="hidden desktop" />

        <Button

          url= { URLS.contentplan }
          text= {(<Fragment>Дальше: создание контент-плана страницы</Fragment>)}
          className="orange text-24 text-center-m"

        />

      </div>

    </Container>

  );

}

export default Landinfo;