import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './map.scss';

const Map = () => {

  

  return (

    <Container className="map flex flex-col">

      <div className="text-47 font-bold text-orange">

        Добавление <br className="mobile"/>местоположения <br className="mobile"/>(точки на карте)

      </div>

      <div className="montserrat text-23 font-semibold text-15-m mt-25 mt-20-m nowrap">

        Если владелец страницы принимает <br className="mobile"/>клиентов в определённом месте, <br className="mobile"/>или таких мест <br className="desktop"/>
        несколько, но они <br className="mobile"/>заранее известны - можно добавить <br className="mobile"/>на страницу карту или несколько карт

      </div>

      <div className="montserrat text-23 font-semibold text-15-m mt-25 mt-20-m nowrap">

        Преимущество карты в том, что <br className="mobile"/>посетитель страницы может быстро <br className="mobile"/>сориентироваться куда <br className="desktop"/>
        ему нужно <br className="mobile"/>будет прийти, а также в один клик <br className="mobile"/>по карте построить маршрут от своего <br />
        местоположения до точки на карте, <br className="mobile"/>и даже при необходимости вызвать <br className="mobile"/>такси в один клик!

      </div>

      <div className="montserrat text-23 font-semibold text-18-m mt-25 mt-35-m nowrap">

        Как добавить карту? Вот так:

      </div>

      <img src="/images/map/main.jpg" className="map__main mt-35 desktop" />
      <img src="/images/map/main-mob.jpg" className="map__main mt-35-m relative mobile" />

      <div className="montserrat mt-20-m ml-5-m flex mobile">

        <div className="montserrat font-semibold text-17-m mobile">1.</div>
        <div className="montserrat font-semibold text-17-m ml-5-m mobile">Выбери пункт “Место на карте”</div>

      </div>

      <div className="montserrat mt-25-m ml-5-m flex mobile">

        <div className="montserrat font-semibold text-17-m mobile">2.</div>
        <div className="montserrat font-semibold text-17-m ml-5-m mobile">В адрес пишем полный адрес, включая дом</div>

      </div>

      <ul className="montserrat mt-15-m">
        
        <li className="montserrat font-semibold text-15-m mb-5-m relative mobile">выбираем нужный вариант <br />из выпадающего списка</li>
        <li className="montserrat font-semibold text-15-m relative mobile">если все сделали правильно, <br />появится предпросмотр карты, <br />и на ней будет отмечен адрес</li>
        
      </ul>

      <div className="montserrat mt-5-m ml-10-m flex mobile">

        <div className="montserrat font-semibold text-17-m mobile">3.</div>
        <div className="montserrat font-semibold text-17-m ml-5-m mobile">Заголовок это то, что написано <br />над картой</div>

      </div>

      <ul className="montserrat mt-15-m">
        
        <li className="montserrat font-semibold text-15-m mb-5-m relative mobile">можно написать сюда <br />название офиса или <br />короткий адрес</li>
        <li className="montserrat font-semibold text-15-m relative mobile">если на странице только <br />одна карта, можно оставить <br />стандартный текст <br />“как нас найти: ”</li>
        
      </ul>

      <div className="map__button button_container flex flex-col-m mt-69 mt-35-m mb-15-m">

        <Button

          url= { URLS.faq }
          text= {`Назад: Добавление ответов\nна частые вопросы`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.shift }
          text= {`Дальше: Как поменять местами элементы`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default Map;