import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './timegap.scss';

const TimeGap = () => {

  return (

    <Container className="timegap">

      <div className="text-47 font-bold text-orange">

        Как «взять перерыв» среди дня

      </div>

      <div className="timegap__row flex flex-col-reverse-m">

        <div className="text_block mt-20">

          <div className="text-23 text-15-m font-semibold mr-16-m mt-15-m">

            Ты можешь назначить любое количество перерывов на любой день.

          </div>

          <div className="text-23 text-15-m font-semibold mt-10 mt-15-m mr-16-m">
            
            Это время будет твоим клиентам недоступно для записи.

          </div>

        </div>

      </div>

      <div className="text-13-m font-semibold mt-25-m mr-16-m mobile">

        Зайди в свой график и нажми на <br />
        “свободное время” в том временном <br />
        промежутке, в котором желаешь <br />
        указать время перерыва.

      </div>

      <img className="timegap__time mt-50 relative desktop" src="/images/timegap/timegap-time.jpg" />
      <img className="timegap__time mt-25-m relative mobile" src="/images/timegap/timegap-time-mobile.jpg" />
      <img className="timegap__sheet mt-60 relative desktop" src="/images/timegap/timegap-sheet.jpg" />
      <img className="timegap__without mt-150 relative desktop" src="/images/timegap/timegap-without.jpg" />

      <div className="text-23 text-14-m font-semibold mt-10 mt-10-m mr-16-m mobile">
            
        Далее нажми “взять перерыв”:

      </div>

      <img className="timegap__clock mt-25-m relative mobile" src="/images/timegap/timegap-clock-mobile.jpg" />

      <div className="text-23 text-14-m font-semibold mt-10 mt-25-m mr-16-m mobile">
            
        Выбери время начала и завершения

      </div>

      <div className="text-23 text-14-m font-semibold mt-10 mt-10-m mr-16-m mobile">

        Далее нажми “подтвердить”

      </div>

      <img className="timegap__during mt-25-m relative mobile" src="/images/timegap/timegap-during-mobile.jpg" />

      <div className="text-23 text-14-m font-semibold mt-10 mt-25-m mr-16-m mobile">
            
        Перерыв добавлен в график!

      </div>

      <div className="text-23 text-13-m font-semibold nowrap mt-10 mt-5-m mr-16-m mobile">

        Люди на это время записаться не смогут

      </div>

      <img className="timegap__done mt-25-m relative mobile" src="/images/timegap/timegap-done-mobile.jpg" />

      <div className="text-20-m font-bold text-center mt-35-m mr-16-m mobile">

        Если перерыв <br />
        уже не нужен

      </div>

      <div className="text-13-m font-semibold mt-30-m mr-16-m mobile">

        Его можно отменить. Для этого нужно <br />
        нажать на перерыв, и в открывшемся <br />
        всплывающем окне нажать <br />
        “удалить перерыв”

      </div>

      <img className="timegap__cancel mt-20-m relative mobile" src="/images/timegap/timegap-cancel-mobile.jpg" />

      <div className="text-14-m font-semibold mt-25-m mr-16-m mobile">

        Время освободится, и клиенты смогут <br />
        на него записаться

      </div>

      <div className="button_container timegap__button flex flex-col-m mt-100 mt-50-m mb-15-m">

        <Button

          url= { URLS.handleclient }
          text= {`Назад:\nкак записать клиента вручную`}
          className="blue text-24 text-center-m"

        />

        <div className="hidden" />

      </div>

    </Container>

  );

}

export default TimeGap;