import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.additem.scss';

const ShopAddItem = () => {

  return (

    <Container className="shopadditem flex flex-col">

      <div className="text-47 font-bold text-orange mt-35">

        Как добавить товар

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-35 mt-25-m ">

        Добавить товар можно нажав в разделе “Мой магазин” кнопку “Добавить товар”. <br /><br />
        Изначально раздел интернет-магазина скрыт. Как его активировать, мы рассказываем <br />
        в предыдущей главе <br className="mobile" />“Как включить функционал интернет-магазина для визитки”

      </div>

      <img src="/images/shopadditem/1-desktop.jpg" className="shopadditem__first relative mt-100 mt-30-m" />

      <div className="flex mt-60 desktop">

        <div className="mt-50 mt-10-m desktop">

          <div className="montserrat text-23 text-16-m font-semibold">

            Для посетителя твоей страницы товар в списке товаров выглядит так:

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-100">

            Видно название (оно не должно быть слишком длинным) и краткое <br />
            описание. Если краткое описание не умещается в карточку товара, <br />
            оно отображается в виде бегущей строки.

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-100">

            Подробное описание отображается только после нажатия на товар.

          </div>

        </div>

        <img src="/images/shopadditem/2-desktop.jpg" className="shopadditem__second relative desktop ml-50" />

      </div>

      <div className="flex mt-100 desktop">

      <img src="/images/shopadditem/3-desktop.jpg" className="shopadditem__third relative desktop ml-50" />

        <div className="mt-80 ml-80 desktop">

          <div className="montserrat text-23 text-16-m font-semibold">

            Заполни анкету: <br /><br />

            В названии не должно быть ничего кроме названия товара. <br /><br />

            В кратком описании - несколько слов описывающих товар, <br />
            сама суть, т.к. эту информацию видно в общем списке товаров <br /><br />

            В подробном описании можно написать много текста. <br />
            Постарайся максимально информативно описать товар. <br />
            Разделяй абзацы пустыми строками, чтобы текст <br />
            лучше воспринимался и не превращался в кашу. <br /><br />

            Можно загрузить до пяти фотографий товара. <br />
            Фотографии должны быть качественными, <br />
            и на них должно быть хорошо видно товар. <br /><br />

            В поле “место на складе” можно для удобства написать, <br />
            как проще будет найти товар - это поле видит только <br />
            владелец страницы <br /><br />

          </div>

        </div>

      </div>

      <div className="flex mt-80 desktop">

        <div className="mt-20 desktop">

          <div className="montserrat text-23 text-16-m font-semibold">

            Ты можешь группировать товары по категориям, <br />
            чтобы покупателю было проще найти нужный товар

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-80">

            Для этого в карточке товара нужно нажать <br />
            “выберите категорию”, и выбрать нужную категорию.

          </div>

          <div className="montserrat text-23 text-16-m font-semibold mt-80">

            Если нужной категории в списке нет - её можно <br />
            добавить. Товар можно прикрепить только к одной <br />
            категории одновременно.

          </div>

        </div>

        <img src="/images/shopadditem/4-desktop.jpg" className="shopadditem__fourth relative desktop ml-80" />

      </div>

      <div className="montserrat text-24-m font-bold text-center mobile">

        Заполни анкету

      </div>

      <img src="/images/shopadditem/3-desktop.jpg" className="shopadditem__second relative mobile mt-50-m" />

      <div className="mt-30-m mobile">

        <div className="montserrat text-16-m font-semibold">

          В названии не должно быть <br />
          ничего, кроме названия товара.

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          В кратком описании - несколько <br />
          слов, описывающих товар, сама суть,
          так как эту информацию видно в общем 
          списке товаров

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          В подробном описании можно <br />
          написать много текста. <br /><br />
          Постарайся максимально информативно описать <br />
          товар. <br /><br />Разделяй абзацы пустыми <br />
          строками, чтобы текст лучше <br />
          воспринимался и не превращался в кашу.

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          Можно загрузить до пяти фотографий 
          товара. Фотографии должны быть
          качественными, и <br />на них должно
          быть хорошо видно товар.

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          В поле “место на складе” можно для
          удобства написать, как проще будет
          найти товар - это поле видит только
          владелец страницы

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          Ты можешь группировать товары по 
          категориям, чтобы покупателю было
          проще найти нужный товар

        </div>

      </div>

      <img src="/images/shopadditem/1-mobile.jpg" className="shopadditem__fm relative mobile mt-20-m" />

      <div className="montserrat text-16-m font-semibold mt-20-m mobile">

        Для этого в карточке товара нужно <br />
        нажать “выберите категорию”, <br />
        и выбрать нужную категорию.

      </div>

      <img src="/images/shopadditem/2-mobile.jpg" className="shopadditem__sm relative mobile mt-20-m" />

      <div className="mt-30-m mobile">

        <div className="montserrat text-16-m font-semibold">

          Если нужной категории в списке нет - её можно добавить.

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          Товар можно прикрепить только <br />
          к одной категории одновременно.

        </div>

        <div className="montserrat text-16-m font-semibold mt-20-m">

          Для этого в карточке товара нужно <br />
          нажать “выберите категорию”, <br />
          и выбрать нужную категорию.

        </div>

      </div>

      <div className="grayline mobile" />

      <div className="montserrat text-16-m font-semibold mt-35-m mobile">

        Для посетителя твоей страницы <br />
        товар в списке товаров выглядит так:

      </div>

      <img src="/images/shopadditem/2-desktop.jpg" className="shopadditem__second relative mobile mt-25-m" />

      <div className="montserrat text-16-m font-semibold mt-20-m mobile">

        видно название (оно не должно быть
        слишком длинным) и краткое <br />
        описание. Если краткое описание <br />
        не умещается в карточку товара, <br />
        оно отображается в виде <br />
        бегущей строки.

      </div>

      <div className="montserrat text-16-m font-semibold mt-20-m mobile">

        подробное описание отображается <br />
        только после нажатия на товар.

      </div>

      <div className="shopadditem__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_open }
          text= {<Fragment><span className="desktop">Назад: как активировать интернет-магазин</span><span className="mobile">Назад: <br />как активировать интернет-магазин</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_itemlist }
          text= {<Fragment><span className="desktop">Дальше: как посмотреть список товаров</span><span className="mobile">Дальше: <br />как посмотреть список товаров</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopAddItem;