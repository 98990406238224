import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import URLS from "../../settings/urls";

import Preparation from "./preparation";
import Landinfo from "./land.info";
import NewSite from "./new.site";
import Links from "./links";
import Background from "./background";
import BackgroundPage from "./background.page";
import TextPage from "./text.page";
import AddImages from "./add.images";
import AddSlider from "./add.slider";
import AddVideos from "./add.videos";
import Faq from "./faq";
import Map from "./map";
import Shift from "./shift";
import ContentPlan from "./content.plan";
import AddingService from "./add.service";
import HowToStart from "./how.to.start";
import SetChedule from "./set.shedule";
import Interface from "./interface";
import NewClient from "./new.client";
import HandleClient from "./handle.client";
import TimeGap from "./time.gap";
import ShopSales from "./shop.sales";
import ShopOpen from "./shop.open";
import ShopAddItem from "./shop.additem";
import ShopItemList from "./shop.itemlist";
import ShopCats from "./shop.cats";
import ShopItemQuestions from "./shop.itemquestions";
import ShopBoughts from "./shop.boughts";
import ShopSalesHystory from "./shop.saleshystory";
import ShopAfterSales from "./shop.aftersales";
import PaymentOffer from "./payment.offer";
import PaymentContract from "./payment.contract";
import PaymentSecret from "./payment.secret";
import PaymentPrepay from "./payment.prepay";

import HeaderMenu from "../components/header-menu";


const AppRouter = () => {

    return (

        <BrowserRouter>

            <div className="container flex">

                <div className="container__menu">
                
                    <HeaderMenu />
                
                </div>

                <div className={`container__content flex-1`}>

                    <Routes>

                        <Route path = "/" element = { <Landinfo />}/>

                        <Route path = { URLS.landinfo } element ={ <Landinfo/> }/>
                        <Route path = { URLS.contentplan } element ={ <ContentPlan/> }/>
                        <Route path = { URLS.create_site } element ={ <NewSite/> }/>
                        <Route path = { URLS.change_site_address } element ={ <NewSite/> }/>
                        <Route path = { URLS.meet_site } element ={ <NewSite/> }/>
                        <Route path = { URLS.avatar_site } element ={ <NewSite/> }/>
                        <Route path = { URLS.short_site_name } element ={ <NewSite/> }/>
                        <Route path = { URLS.site_urls } element ={ <Links/> }/>
                        <Route path = { URLS.background } element ={ <Background/> }/>
                        <Route path = { URLS.backgroundpage } element ={ <BackgroundPage/> }/>
                        <Route path = { URLS.textpage } element ={ <TextPage/> }/>
                        <Route path = { URLS.addimages } element ={ <AddImages/> }/>
                        <Route path = { URLS.addslider } element ={ <AddSlider/> }/>
                        <Route path = { URLS.addvideos } element ={ <AddVideos/> }/>
                        <Route path = { URLS.faq } element ={ <Faq/> }/>
                        <Route path = { URLS.geo } element ={ <Map/> }/>
                        <Route path = { URLS.shift } element ={ <Shift/> }/>

                        <Route path = { URLS.preparing } element = { <Preparation /> }/>
                        <Route path = { URLS.howtostart } element ={ <HowToStart/> }/>
                        <Route path = { URLS.addingservice } element ={ <AddingService/> }/>
                        <Route path = { URLS.setchedule } element ={ <SetChedule/> }/>
                        <Route path = { URLS.interface } element ={ <Interface/> }/>
                        <Route path = { URLS.newclient } element ={ <NewClient/> }/>
                        <Route path = { URLS.handleclient } element ={ <HandleClient/> }/>
                        <Route path = { URLS.timegap } element ={ <TimeGap/> }/>

                        <Route path = { URLS.shop_sales } element ={ <ShopSales/> }/>
                        <Route path = { URLS.shop_open } element ={ <ShopOpen/> }/>
                        <Route path = { URLS.shop_additem } element ={ <ShopAddItem/> }/>
                        <Route path = { URLS.shop_itemlist } element ={ <ShopItemList/> }/>
                        <Route path = { URLS.shop_cats } element ={ <ShopCats/> }/>
                        <Route path = { URLS.shop_itemquestions } element ={ <ShopItemQuestions/> }/>
                        <Route path = { URLS.shop_boughts } element ={ <ShopBoughts/> }/>
                        <Route path = { URLS.shop_saleshystory } element ={ <ShopSalesHystory/> }/>
                        <Route path = { URLS.shop_aftersales } element ={ <ShopAfterSales/> }/>
                        <Route path = { URLS.shop_aftersalesfix } element ={ <ShopAfterSales/> }/>

                        <Route path = { URLS.payment_offer } element ={ <PaymentOffer/> }/>
                        <Route path = { URLS.payment_contract } element ={ <PaymentContract/> }/>
                        <Route path = { URLS.payment_secret } element ={ <PaymentSecret/> }/>
                        <Route path = { URLS.payment_prepay } element ={ <PaymentPrepay/> }/>

                    </Routes>
                
                </div>

            </div>
        
        </BrowserRouter>

    );

}

export default AppRouter;