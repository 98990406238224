import React, { useState, useEffect } from 'react';

import URLS from '../../../settings/urls';

import Button from '../../components/button';
import Container from '../../components/container';

import { ReactComponent as ArrowLeft } from '../../../images/arrow-left.svg';

import './addingservice.scss';

const AddingService = () => {

  const [ text, setText ] = useState ("");

  return (

    <Container className='adding_service'>
      
      <div className="text-47 font-bold text-orange">

        Добавление услуги

      </div>

      <div className="text-23 font-semibold mt-24 mt-24-m nowrap">

        При добавлении услуги важно <br className='mobile'/>однозначно описать её суть, <br className='mobile'/>добавить красивые фотографии <br className='mobile'/>в карточку,<br className='desktop'/>
        подробно описать <br className='mobile'/>все плюсы и преимущества, <br className='mobile'/>которые клиент получит, <br className='mobile'/>когда придёт на неё.<br className='mobile'/><br className='mobile'/><br className='mobile'/>

      </div>

      <div className="adding_service__row flex flex-col-reverse-m  mt-40 items-center ">

        <img src="/images/addingservice/services-icon.jpg" />
        
        <ArrowLeft className="desktop" />

        <div className="text-23 font-semibold">

          Чтобы попасть в раздел твоих услуг, нужно зайти в онлайн-запись, и нажать на эту кнопку

        </div>

      </div>

      <div className="adding_service__row flex flex-col-reverse-m  mt-24 items-center">

        <img src="/images/addingservice/add-service-icon.jpg" />

        <ArrowLeft className="desktop" />

        <div className="text-23 font-semibold">

          Чтобы добавить новую услугу, нажми на эту кнопку <br />в разделе услуг<br /><br />
          Откроется карточка добавления <br className='mobile'/>услуги, её разбираем ниже:

        </div>
        
      </div>

      <img className="guide desktop mt-52" src = "/images/addingservice/guide-desktop.jpg" />
      <img className="guide mobile" src = "/images/addingservice/guide-mobile.jpg" />
      
      <div className="text-23 font-semibold mobile mt-33">

        После заполнения всех полей, <br className='mobile'/>осталось только нажать на эту кнопку, и услуга добавлена!

      </div>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">

        <Button

          url= { URLS.howtostart }
          text= {`Назад:\nкак попасть в онлайн-запись`}
          className="blue text-24 text-center-m "

        />

        <Button

          url= { URLS.setchedule }
          text= {`Дальше:\nнастройка рабочего графика`}
          className="orange ml-26 mt-12-m text-24 text-center-m"

        />

      </div>

    </Container>

  );

}

export default AddingService;