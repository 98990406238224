import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.boughts.scss';

const ShopBoughts = () => {

  return (

    <Container className="shopboughts flex flex-col">

      <div className="text-47 font-bold text-orange mt-35">

        Как узнать, <br className="mobile" />что твой товар купили

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-60 mt-25-m">

        Чтобы твой товар могли купить через Any Link, необходимо интегрировать платёжную систему. <br />
        <br className="mobile" />Это несложно, но так как процесс объёмный, этому посвящена отдельная глава.<br /><br />
        Здесь же предполагается, что платёжная система уже была интегрирована. <br />
        <br className="mobile" />Покупатель зашёл на твою страницу, выбрал нужные товары <br className="mobile" />и оплатил их. Как об этом узнать?

      </div>

      <div className="flex">

        <img src="/images/shopboughts/1-desktop.jpg" className="shopboughts__first relative desktop mt-55 mt-5-m" />

        <div className="montserrat text-23 text-16-m font-semibold mt-100 mt-25-m ml-60">

          Когда покупатель купил товары, <br />
          продавцу приходит уведомление, <br />
          а также письмо на электронную почту. <br /><br />
          <br className="desktop" /><br className="desktop" />
          Колокольчик начинает качаться, <br className="mobile" />
          и рядом <br className="desktop" /> с ним располагается счётчик новых уведомлений

        </div>

        <img src="/images/shopboughts/1-desktop.jpg" className="shopboughts__first relative mobile mt-25-m" />

      </div>

      <div className="flex mt-60 mt-25-m">

        <img src="/images/shopboughts/2-desktop.jpg" className="shopboughts__second desktop relative" />

        <div>

        <div className="montserrat text-23 text-16-m font-semibold mt-80 mt-1-m ml-60">

          Если нажать на колокольчик, откроются уведомления. <br /><br className="mobile" />
          Там можно увидеть сумму заказа <br className="mobile" />и имя покупателя. <br /><br className="mobile" />
          <img src="/images/shopboughts/2-desktop.jpg" className="shopboughts__second mobile relative" />
          <br className="mobile" />Нажми “подробнее”, чтобы перейти к заказу.

        </div>

       </div>

      </div>
            
      <div className="shopboughts__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_itemquestions }
          text= {<Fragment><span className="desktop">Назад: вопросы о товарах от покупателей</span>
          <span className="mobile">Назад: <br />вопросы о товарах от покупателей</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_saleshystory }
          text= {<Fragment><span className="desktop">Дальше: как посмотреть историю продаж</span>
          <span className="mobile">Дальше: <br />как посмотреть историю продаж</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopBoughts;