import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Accordeon from '../accordeon';
import URLS from '../../../settings/urls';
import cssIf from '../../../scripts/helpers/class.add.if';

import { ReactComponent as Opener } from './icons/opener.svg';
import { ReactComponent as Closer } from './icons/closer.svg';
import { ReactComponent as HeaderLogo } from './icons/logo-head.svg';
import { ReactComponent as HeaderCloser } from './icons/mm-closer.svg';

import './header-menu.scss';


const HeaderMenu = () => {

  const [ isOpened, setIsOpened ] = useState( false );
  function toggle() { setIsOpened( !isOpened ) };

  const navigate = useNavigate();

  function handleNavigate( url, scrollTop = true ) {

    navigate( url );
    scrollTop && setTimeout( () => { window.scrollTo(0,0) }, 250 );
    setIsOpened( false );

  }

  const MenuList = [
    
    {

      name: "Интернет-визитка",

      chapters: [

        {

          name: "Подготовка к настройке",

          themes: [

            {

              name: "Какая информация потребуется?",
              url: URLS.landinfo

            },

            {

              name: "Создание контент-плана страницы",
              url: URLS.contentplan

            }

          ] 

        },

        {

          name: "Базовая настройка",

          themes: [

            {

              name: "Создание нового сайта",
              url: URLS.create_site

            },

            {

              name: "Изменение адреса сайта",
              url: URLS.change_site_address,
              disableScrollTop: true

            },
            
            {

              name: "Знакомство с конструктором",
              url: URLS.meet_site,
              disableScrollTop: true

            },

            {

              name: "Изменение аватарки сайта",
              url: URLS.avatar_site,
              disableScrollTop: true

            },

            {

              name: "Добавление названия\nи описания на визитку",
              url: URLS.short_site_name,
              disableScrollTop: true

            },

            {

              name: "Добавление способов связи\nи ссылок на соцсети/сайты",
              url: URLS.site_urls

            },

            {

              name: "Настройка фона шапки",
              url: URLS.background

            },

            {

              name: "Настройка фона страницы",
              url: URLS.backgroundpage

            },

            {

              name: "Наполнение страницы текстом",
              url: URLS.textpage

            },

            {

              name: "Добавление изображений",
              url: URLS.addimages

            },

            {

              name: "Добавление слайдера-карусели",
              url: URLS.addslider

            },

            {

              name: "Добавление видео",
              url: URLS.addvideos

            },

            {

              name: "Добавление ответов\nна частые вопросы",
              url: URLS.faq

            },

            {

              name: "Добавление местоположения\n(точка на карте)",
              url: URLS.geo

            },

            {

              name: "Как поменять местами элементы",
              url: URLS.shift

            }

          ] 

        },       
      
      ]
      
    },

    {

      name: "Интернет-магазин",

      chapters: [

        {

          name: "Первый шаг",

          themes: [

            {

              name: (<Fragment>Как продавать товары<br />через Any Link</Fragment>),
              url: URLS.shop_sales

            },

            {

              name: (<Fragment>Как включить функционал<br />инетернет-магазина для визитки</Fragment>),
              url: URLS.shop_open

            }

          ] 

        },

        {

          name: "Наполнение товаром",

          themes: [

            {

              name: (<Fragment>Как добавить товар</Fragment>),
              url: URLS.shop_additem

            },

            {

              name: (<Fragment>Как посмотреть список товаров</Fragment>),
              url: URLS.shop_itemlist

            },
            
            {

              name: (<Fragment>Как изменить или удалить товар</Fragment>),
              url: URLS.shop_itemlist

            },

            {

              name: (<Fragment>Как управлять категориями</Fragment>),
              url: URLS.shop_cats

            }

          ]

        },

        {

          name: "Обработка заказов",

          themes: [

            {

              name: (<Fragment>Вопросы о товарах от покупателей</Fragment>),
              url: URLS.shop_itemquestions

            },

            {

              name: (<Fragment>Как узнать, что твой товар купили</Fragment>),
              url: URLS.shop_boughts

            },
            
            {

              name: (<Fragment>Как посмотреть историю продаж</Fragment>),
              url: URLS.shop_saleshystory

            },

            {

              name: (<Fragment>У тебя купили товар. Что дальше?</Fragment>),
              url: URLS.shop_aftersales

            },

            {

              name: (<Fragment>Как зафиксировать выдачу товара</Fragment>),
              url: URLS.shop_aftersalesfix

            }

          ]

        },

        {

          name: "Приём предоплаты",

          themes: [

            {

              name: "Подключение платёжной системы",
              url: URLS.payment_offer

            },

            {

              name: "Подписание договора",
              url: URLS.payment_contract

            },

            {

              name: "Получение секретного ключа",
              url: URLS.payment_secret

            },

            {

              name: "Включение предоплаты для услуги",
              url: URLS.payment_prepay

            }

          ]

        }

      ]

    },

    {

      name: "Онлайн-запись",

      chapters: [

        {

          name: "Подготовка к настройке",

          themes: [

            {

              name: (<Fragment>Обязательно к прочтению<br />Как работает онлайн запись<br />Any Link</Fragment>),
              url: URLS.preparing

            }

          ] 

        },

        {

          name: "Настройка графика и услуг",

          themes: [

            {

              name: "Как попасть в онлайн-запись",
              url: URLS.howtostart

            },

            {

              name: "Добавление услуги",
              url: URLS.addingservice

            },

            {

              name: "Настройка рабочего графика",
              url: URLS.setchedule

            }

          ] 

        },

        {

          name: "Как работать с расписанием",

          themes: [

            {

              name: "Знакомство с интерфейсом",
              url: URLS.interface

            },

            {

              name: "Клиент записался - что делать",
              url: URLS.newclient

            },

            {

              name: "Как записать клиента вручную",
              url: URLS.handleclient

            },

            {

              name: "Как “взять перерыв” среди дня",
              url: URLS.timegap

            }

          ] 

        }

      ]

    },
    
  ]

  return (

    <div className={`header-menu fixed flex flex-col ${ isOpened ? "header-menu--opened" : "" }`}>

      <div className="flex header-menu__border">

        <HeaderLogo className="header-menu__logo" />

        <div className="header-menu__titles flex flex-col justify-between">

          <p className="montserrat text-orange__deep text-24 font-bold header-menu__text">

            Any Link

          </p>

          <p className="montserrat text-orange text-18 font-semibold header-menu__subtext">

            Руководство по настройке

          </p>

        </div>

        { isOpened
        
            ?
            
              <HeaderCloser className="header-menu__closer-button mobile" onClick={() => { toggle() }}/>
          
            :

              <div className="header-menu__opener-button flex flex-col justify-between items-center mobile" onClick={() => { toggle() }}>

                <div className="header-menu__opener-button__liner header-menu__opener-button__liner__thin" />
                <div className="header-menu__opener-button__liner" />
                <div className="header-menu__opener-button__liner header-menu__opener-button__liner__bold" />

                <p className="header-menu__opener-button__text font-medium roboto">меню</p>

                <div className="header-menu__opener-button__liner header-menu__opener-button__liner__bold" />
                <div className="header-menu__opener-button__liner" />
                <div className="header-menu__opener-button__liner header-menu__opener-button__liner__thin" />

              </div>

        }

      </div>

      <div className={`header-menu__body flex flex-col ${ isOpened ? "header-menu__body--opened" : "header-menu__body--closed" }`}>

        { MenuList.map(( el, key1 ) => (

          <Accordeon

            key = { key1 }
            defaultOpened = { key1 === 0 }

            head = {(

              <div className="flex relative mt-special">

                <p className="roboto text-31 font-semibold">{ el.name }</p>

                <div className="header-menu__body__toggle relative">
                  
                  <Opener className="header-menu__opener header-menu__opener_big" />
                  <Closer className="header-menu__closer header-menu__closer_big" />

                </div>

              </div>

            )}

          >

            { el.chapters.map(( chapter, key2 ) => (

              <Accordeon

                key = { key2 }
                head = {(

                  <div className="header-menu__body flex relative">

                    <p className="roboto header-menu__body__text header-menu__subtext text-gray text-23 font-semibold">{ chapter.name }</p>
                    
                    <div className="header-menu__body__toggle relative">

                      <Opener className="header-menu__opener" />
                      <Closer className="header-menu__closer" />
                  
                    </div>

                  </div>

                )}

              >

                { chapter.themes.map(( theme, key3 ) => (

                  <MenuLink

                    key={ key3 }
                    text={ theme.name }
                    url={ theme.url }
                    navigate={ () => handleNavigate( theme.url, !theme?.disableScrollTop ) }

                  />

                ))}

              </Accordeon>
              
            ))}
  
          </Accordeon>

        ))}

      </div>

    </div>

  );

}

const MenuLink = ({ url = "", text = "", navigate = () => {} }) => {
  
  const currentPage = window.location.pathname;

  return (

    <div className={`header-menu__body__text__link ${ cssIf( url === currentPage, "header-menu__body__text__link--active") }`} onClick={() => { navigate(url) }}>

      <p className="pointer roboto header-menu__body__subtext header-menu__body__text__link__subtext pl-15 text-gray__light text-19 font-medium">{ text }</p>

    </div>
  
  );

}
  
export default HeaderMenu;