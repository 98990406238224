import React, { useEffect } from 'react';
import Container from '../../components/container';

import URLS from '../../../settings/urls';

import { ReactComponent as ArrowLeft } from '../../../images/arrow-left.svg';
import { ReactComponent as Warn } from '../../../images/tip.svg';

import { Link } from "react-router-dom";
import Button from '../../components/button';

import './setchedule.scss';

const SetChedule = () => {

  

  return (

    <Container className="customization relative">

      <div className="text-47 font-bold text-orange">

        Настройка <br className='mobile'/>рабочего графика

      </div>

      <div className="text-23 font-semibold mt-25">

        Прежде чем дать людям возможность записываться <br className='mobile'/>к тебе на приём, нужно выбрать <br className='desktop' />
        удобные для <br className='mobile'/>приёма дни и время.<br className='mobile'/><br className='mobile'/> Any Link предлагает <br className='mobile'/>два варианта настройки: <br className='mobile'/>быстрый и продвинутый.

      </div>

      <div className="text-35 font-bold mt-35">

        Как попасть <br className='mobile'/>в настройку графика

      </div>

      <div className="customization__row flex flex-col-reverse-m mt-40 items-center">

        <img className='set-grey_img' src = "/images/customizacion/set-grey.jpg" />
        
        <ArrowLeft className="desktop ml-41" />

        <div className="text-23 font-semibold ml-26">

          В разделе онлайн-записи <br className='mobile'/>нажми на эту кнопку

        </div>
        
      </div>

      <div className="customization__row flex flex-col-reverse-m mt-28">

        <img className='set-orange_img desktop' src = "/images/customizacion/set-orange.jpg" />
        <img className='set-orange_img mobile' src = "/images/customizacion/mobil.jpg" />

        <div className="row_block flex items-center mt-93">
          
          <ArrowLeft className="desktop ml-14" />

          <div className="text-23 font-semibold ml-26 mb-11-m">

            А потом на эту, и откроется <br className='mobile'/>настройка графика!

          </div>

        </div>

      </div>

      <div className="text-35 font-bold mt-55">

        Чем отличаются режимы <br className='mobile'/>настройки графика

      </div>

      <div className="text-23 font-semibold mt-43">

        В режиме “дни недели” достаточно настроить время приёма на каждый <br className='mobile'/>из семи дней недели.

      </div>

      <div className="text-23 font-semibold mt-40">

        В режиме “конкретные даты” <br className='mobile'/>можно настроить каждый день <br className='mobile'/>по отдельности.

      </div>

      <div className="text-23 font-semibold mt-40">

        Переключать режимы <br className='mobile'/>настройки можно, нажимая <br className='mobile'/>на переключатель:

      </div>

      <img className='button_img' src = "/images/customizacion/buttons.jpg" />

      <div className="text-23 font-semibold mt-28">

        Рекомендуется сначала сделать общую настройку в режиме “дни недели”, и затем <br className='desktop'/>
        при необходимости настраивать отдельные дни в режиме “конкретные даты”

      </div>

      <div className="text-35 font-bold mt-64 mt-31-m">

        Режим настройки: <br className='mobile'/>Дни недели

      </div>

      <div className="customization__block flex flex-col-m mt-43">
     
        <img className='set-week_img desktop' src = "/images/customizacion/set-week.jpg" />
        <img className='set-week_img mobile' src = "/images/customizacion/set-week-mobil.jpg" />
        
        <div className="sidebar ">

          <div className="text-23 font-semibold pl-20 mt-276">

            Нажимай на дни недели, <br className='mobile'/>и внизу увидишь<br />
            настройки для этого дня

          </div>

          <div className="text-23 font-semibold pl-20 mt-100 desktop">

            Если нужно изменить настройки, нажми сюда

          </div>

          <div className="text-23 font-semibold pl-20 mt-100 mt-25-m mobile">

            Если нужно изменить <br className='mobile'/>настройки, нажми <br className='mobile'/>“изменить <br className='mobile'/>настройки дня”

          </div>

        </div>

      </div>

      <img className='set-week desktop' src = "/images/customizacion/set-week2.jpg" />
      <img className='set_week mobile mt-24' src = "/images/customizacion/set-week2-mobil.jpg" />

      <div className="text-23 text-16-m font-semibold mt-50">

          Самый простой способ настройки - <br className='mobile'/>если график на каждый рабочий день один и тот же, <br className='desktop'/>
          сначала настроить рабочие дни: выставить настройки, и нажать “установить <br className='mobile'/>для всех дней”.<br /><br className='mobile'/>
          Затем нажимай на дни <br className='mobile'/>недели, которые будут выходными, <br className='mobile'/>и отмечай их, как выходные.<br /><br className='mobile'/>
          И всё - после этого график настроен!

      </div>
 
      <div className="text-35 font-bold mt-89">

        Режим настройки: Конкретные даты

      </div>

      <img className='set desktop mr-22' src = "/images/customizacion/set.jpg" />
      <img className='set-mobil mobile mt-27-m' src = "/images/customizacion/set-mobil.jpg" />
        
      <div className="tip_container flex items-center mt-35 pl-10">

        <Warn />

        <div className="text-23 text-blue font-semibold pl-15 desktop">

          Если тебе нужно установить нерабочее время (кроме обеда) внутри рабочего дня, это можно<br />
          сделать в самом графике. Читай раздел справки "<Link to="/" className='link text-blue'>как взять перерыв среди дня</Link>"

        </div>
        
        <div className="text-13-m text-blue font-semibold ml-10-m mt-15-m nowrap mobile">

          Если тебе нужно установить <br />нерабочее время (кроме обеда) <br className='mobile'/>внутри рабочего дня, это можно<br />
          сделать в самом графике. <br className='mobile'/><br className='mobile'/>

        </div>
      
      </div>

      <div className="text-18-m text-blue font-semibold pl-16-m mt-6-m nowrap mobile">

        Читай раздел справки <br />"<Link to="/" className='link text-blue'>как взять перерыв среди дня</Link>"

      </div>

      <div className="button_container customization__button flex flex-col-m mt-60 mt-50-m mb-15-m">

        <Button

          url= { URLS.howtostart }
          text= {`Назад:\nкак попасть в онлайн-запись`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.interface }
          text= {`Дальше:\nкак работать с расписанием`}
          className="orange ml-26 text-24 mt-12-m text-center-m"
      
        />

      </div>

    </Container>

  );

}

export default SetChedule; 