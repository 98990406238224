import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.saleshystory.scss';

const ShopSalesHystory = () => {

  return (

    <Container className="shopsaleshystory flex flex-col">

      <div className="text-47 font-bold text-orange mt-35">

        Как посмотреть <br className="mobile" />историю продаж

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-40 mt-25-m">

        История всех заказов от покупателей хранится в подразделе “касса и выдача”  раздела “мой магазин”

      </div>

      <img src="/images/shopsaleshystory/1-desktop.jpg" className="shopsaleshystory__first relative mt-20-m mobile" />
      <img src="/images/shopsaleshystory/2-desktop.jpg" className="shopsaleshystory__second relative mt-20-m mobile" />

      <div className="flex mt-50">

        <img src="/images/shopsaleshystory/1-desktop.jpg" className="shopsaleshystory__first relative desktop" />
        <img src="/images/shopsaleshystory/2-desktop.jpg" className="shopsaleshystory__second relative desktop ml-40" />

      </div>

      <div className="flex mt-120 mt-20-m">

        <img src="/images/shopsaleshystory/3-desktop.jpg" className="shopsaleshystory__third relative desktop mt-25-m" />

        <div className="montserrat text-23 text-16-m font-semibold mt-40 mt-5-m ml-60 mt-minus">

          Здесь отображаются как <br className="mobile"/>прошлые так <br className="desktop"/>
          и текущие заказы, они сгруппированы по дням. <br />
          <img src="/images/shopsaleshystory/3-desktop.jpg" className="shopsaleshystory__third relative mobile mt-25-m" />
          Новые находятся выше, <br className="mobile"/>старые ниже. <br />
          <br /><br className="desktop"/>
          При нажатии на заказ откроется <br />
          окно с подробной информацией         

        </div>

      </div>

      <div className="shopsaleshystory__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_boughts }
          text= {<Fragment><span className="desktop">Назад: как узнать, что твой товар купили</span><span className="mobile">Назад: <br className="mobile" />как узнать, что твой товар купили</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <Button

          url= { URLS.shop_aftersales }
          text= {<Fragment><span className="desktop">Дальше: у тебя купили товар. Что делать?</span><span className="mobile">Дальше: <br className="mobile" />у тебя купили товар. что делать?</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopSalesHystory;