import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import { ReactComponent as ArrowUp } from '../../../images/arrow-up.svg';

import './preparation.scss';

const Preparation = () => {

  

  return (

    <Container className='preparation'>
      
      <div className="text-47 font-bold text-orange">

        Важно знать <br className="mobile" />перед настройкой <br className="mobile" />онлайн-записи

      </div>

      <div className="text-23 font-semibold mt-24 mt-24-m">

        Чтобы люди записывались к тебе на приём, первое что нужно сделать - это <br className="mobile" />настроить интернет-визитку.

      </div>

      <div className="text-23 font-semibold mt-33">

        Интернет-визитка это твой <br className="mobile" />собственный мини-сайт, который настраивается в бесплатном <br className="desktop" />
        интуитивном конструкторе. <br className="mobile" /><br className="mobile" />Клиенты заходят на него по ссылке, читают размещённую на нём <br className="desktop" />
        информацию, и могут открыть перечень твоих услуг, чтобы записаться на любую <br className="mobile" />из них.

      </div>

      <div className="text-23 font-semibold mt-33">

        Сразу после регистрации в главном меню ты увидишь кнопку “моя визитка”, <br className="desktop" />
        нажав <br className="mobile" />на неё ты попадешь в конструктор собственной интернет-визитки.

      </div>

      <div className="text-23 font-semibold mt-33">

        Размести на визитке информацию о себе, и добавь кнопки способов связи <br className="mobile" />с тобой.<br /><br className="mobile" />
        Качественной настройке визитки посвящён отдельный раздел справочника, рекомендую <br className="desktop" />
        с ним <br className="mobile" />ознакомиться: <Link to="/" className="link text-blue">читать про <br className="mobile" />настройку интернет-визитки</Link>

      </div>

      <div className="text-47 font-bold text-orange mt-54">

        Как работает <br className="mobile" />онлайн-запись Any Link?

      </div>

      <div className="preparation__actions flex flex-col-m mt-54">

        <div className="preparation__actions__col">

          <div className="text-35 font-bold">

            Твои действия:

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              1

            </div>

            Настраиваешь <br className="mobile" />интернет-визитку: <br />
            рассказываешь в ней о себе и добавляешь <br className="desktop" />
            способы связи с тобой

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              2

            </div>

            Добавляешь услуги которые оказываешь: <br />
            их название, стоимость, длительность и тд.<br /><br />
            Прикрепляешь к ним <br className="mobile" />фотографии. <br /><br className="mobile" />
            Можешь включить требование предоплаты <br className="desktop" />
            при записи, и указать её размер.

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              3

            </div>

            Настраиваешь свой рабочий график: <br /><br />
            выбираешь дни и время <br className="mobile" />когда готов(а) <br className="desktop" />
            принимать людей к себе на приём.

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              4

            </div>

            Размещаешь ссылку на свою страницу <br className="desktop"/>
            у себя в соцсетях. <br className="mobile" /><br className="mobile" />
            Также можешь <br className="desktop" />запустить на неё рекламу 

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              5

            </div>

            Когда клиент записывается, тебе <br className="mobile" />приходит <br className="desktop" />
            уведомление и письмо на почту. Также <br className="desktop"/>
            бронь отражается в твоём графике Any Link.<br /><br className="mobile" />
            На это время никто другой <br className="mobile" />записаться <br className="desktop" />
            уже не сможет! <br className="mobile" /><br className="mobile" />Тебе нужно нажать “подтвердить бронь”, чтобы клиент увидел, <br />
            что ты его будешь ждать.

          </div>

        </div>

        <div className="text-blue text-23 font-semibold mobile mt-42-m">

          Пункты 1, 2, 3, 4 ты делаешь <br className="mobile" />только один раз <br className="desktop" />
          <span className="text-17"><br className="mobile" /><br className="mobile" />(за исключением случаев, <br className="mobile" />когда ты хочешь дополнить <br className="mobile" />информацию)</span>

        </div>

        <div className="preparation__actions__col flex-1">

          <div className="text-35 font-bold">

            Действия твоих клиентов:
 
          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              1

            </div>

            Попадает на твою страницу по твоей <br className="desktop" />
            индивидуальной ссылке. Ознакамливается <br />
            с информацией о тебе и перечнем услуг

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              2

            </div>

            Выбирает нужную услугу, читает её <br className="desktop" />
            подробное описание. Принимает решение <br className="desktop" /> записаться

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              3

            </div>

            Выбирает доступный для записи день <br className="desktop" />
            и свободное время. <br className="desktop" />
            Указывает свои контактные данные: <br className="mobile" />ФИО, <br className="desktop" />
            телефон и адрес электронной почты.

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              4

            </div>

            Записывается, если включена предоплата, <br className="desktop" />
            то вносит предоплату перед записью. <br className="desktop" />
            Предоплата сразу зачисляется к тебе на карту.

          </div>

          <div className="text-23 font-semibold mt-38 pl-40 relative">

            <div className="preparation__actions__col__number text-44 font-extrabold absolute text-center">

              5

            </div>

            Клиент теперь видит информацию о своей <br className="desktop" />
            брони прямо на твоей интернет-визитке! <br className="desktop" />
            Когда ты подтверждаешь бронь, ему приходит <br className="desktop" />
            уведомление, в том числе на электронную почту <br className="desktop" />

          </div>
                  
        </div>

      </div>

      <div className="preparation__actions_tip flex mt-43 desktop">

        <ArrowUp />

        <div className="text-blue text-23 font-semibold">

          Пункты 1, 2, 3, 4 ты делаешь только <br className="mobile" />один раз <br className="desktop" />
          <span className="text-17">(за исключением случаев, <br className="mobile" />когда ты хочешь дополнить <br className="mobile" />информацию)</span>

        </div>

      </div>

      <div className="text-23 font-semibold mt-73">

        Алгоритм Any Link специально <br className="mobile" />предлагает твоим клиентам такое время, чтобы несколько клиентов <br className="desktop" />
        могли записаться сразу друг после друга: пришёл один клиент, сразу <br className="mobile" />после него второй, <br className="desktop" />
        после второго - третий, и так далее, если налажен поток клиентов.

      </div>

      <div className="text-23 font-semibold mt-40">

        Таким образом можно принять <br className="mobile" />наибольшее количество клиентов. <br className="mobile" /><br className="mobile" />А чтобы отдохнуть перед приёмом <br className="desktop" />
        следующего клиента, можно настроить время отдыха после каждой из оказываемых услуг, и это время <br className='desktop'/>
        будет <br className="mobile" />учитываться при выдаче свободного времени клиентам.
              
      </div>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">

        <div className="hidden desktop" />

        <Button

          url= { URLS.howtostart }
          text= {`Дальше:\nкак попасть в онлайн-запись`}
          className="orange text-24 text-center-m"

        />

      </div>

    </Container>

  );

}

export default Preparation;