import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './payment.offer.scss';

const PaymentOffer = () => {

  return (

    <Container className="payment-offer flex flex-col">

      <div className="text-47 font-bold text-orange">

        Подключение платёжной системы

      </div>

      <div className="text-23 font-semibold mt-35 mt-20-m">

        Ты можешь принимать оплату онлайн от клиентов благодаря интеграции с сервисом ЮKassa. <br /><br className="mobile" />
        Как это сделать, описано ниже:

      </div>

      <div className="text-23 font-semibold mt-35 mt-25-m">

        1. Зайди на сайт yookassa.ru и  нажми “подключить Юкасса”

      </div>

      <img src="/images/paymentoffer/1-desktop.jpg" className="payment-offer__img mt-30 desktop"/>
      <img src="/images/paymentoffer/1-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        2. Введи номер телефона, или нажми “войти через Сбер ID”

      </div>

      <div className="text-20 font-semibold mt-10 desktop">

        Мы рекомендуем использовать авторизацию через Сбер, так как это быстрее. <br />
        Далее будет описан полный путь регистрации по номеру телефона.

      </div>

      <img src="/images/paymentoffer/2-desktop.jpg" className="payment-offer__img mt-30 desktop"/>
      <img src="/images/paymentoffer/2-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>

      <div className="text-16-m font-semibold mt-15-m mobile">

        Мы рекомендуем использовать авторизацию через Сбер, <br className="mobile" />так как это быстрее. <br /><br className="mobile" />
        Далее будет описан полный путь регистрации по номеру телефона.

      </div>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        Если выбрана регистрация по номеру телефона, после ввода номера на телефон поступит звонок. <br /><br className="mobile" />
        Осталось ввести 4 последние цифры звонившего.

      </div>

      <img src="/images/paymentoffer/3-desktop.jpg" className="payment-offer__img mt-30 desktop"/>
      <img src="/images/paymentoffer/3-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        3. Придумываем надежный пароль. Пароль должен состоять из латинских букв (!) строчных и заглавных, <br />
        а также цифр.

      </div>

      <img src="/images/paymentoffer/4-desktop.jpg" className="payment-offer__img mt-30 desktop"/>
      <img src="/images/paymentoffer/4-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        4. Придумай имя для работы в ЮKassa. Можно использовать любое имя. Также укажи рабочую почту.

      </div>

      <img src="/images/paymentoffer/5-desktop.jpg" className="payment-offer__img mt-30 desktop"/>
      <img src="/images/paymentoffer/5-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        Завершаем регистрацию в качестве юридического лица или самозанятого. <br /><br className="mobile" />
        Нужно будет указать свой ИНН.

      </div>

      <img src="/images/paymentoffer/6-desktop.jpg" className="payment-offer__img mt-30 desktop"/>
      <img src="/images/paymentoffer/6-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>
      <img src="/images/paymentoffer/7-desktop.jpg" className="payment-offer__img mt-50 desktop"/>
      <img src="/images/paymentoffer/7-mobile.jpg" className="payment-offer__img mt-25-m mobile"/>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">

        <div className="hidden desktop" />
      
        <Button

          url= { URLS.payment_contract }
          text= {`Дальше:\nподписание договора`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default PaymentOffer;