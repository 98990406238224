import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './text.page.scss';

const TextPage = () => {  

  return (

    <Container className="textpage flex flex-col">

      <div className="text-47 font-bold text-orange">

        Наполнение страницы <br className="mobile" />текстом

      </div>

      <img src="/images/textpage/add-point.jpg" className="textpage__add-point mt-10 ml-20-m relative mobile" />

      <div className="textpage__ul flex justify-between mt-20 mt-10-m relative">

        <div>

          <div className="montserrat text-28 text-16-m font-bold mob-cen mt-10">

            Особенности текстового элемента:

          </div>

          <ul className="montserrat text-23 text-14-m font-semibold mt-25 mt-25-m nowrap">

            <li className="mb-20 mb-10-m">заголовок - шрифт крупный и жирный</li>
            <li className="mb-20 mb-10-m">текст - обычный текст для абзацев</li>
            <li className="mb-20 mb-10-m">бегущая строка - оживляет страницу</li>
            <li className="mb-20 mb-10-m">любому тексту можно задать цвет и <br />цвет фона под текстом</li>
            <li className="mb-20 mb-10-m">заголовок и текст можно выровнять <br /> по левому краю/правому краю/по центру</li>

          </ul>

        </div>

        <img src="/images/textpage/add-point.jpg" className="textpage__add-point mt-10 relative desktop" />

      </div>

      <div className="montserrat text-44 text-23-m font-bold mt-30 mt-35-m mb-25-m mob-cen mob-cen__lefter">

        Рекомендации:

      </div>

      <div className="textpage__blocks mt-10-m mobile">

        <div className="textpage__liner relative" />

        <div className="flex mobile">
          
          <div className="montserrat text-16-m font-bold mt-20-m">1.</div>
          <div className="montserrat text-16-m font-bold mt-20-m ml-5-m">Разделяй весь контент <br />на заголовки и абзацы</div>

        </div>

        <img src="/images/textpage/first-mob.jpg" className="textpage__img relative mt-40 mt-20-m mobile" />

        <ul className="montserrat text-14-m font-medium nowrap">

          <li className="mb-3-m">чередуй их от более важных <br />к менее важным</li>
          <li className="mb-3-m">не пиши много текста в заголовок</li>
          <li className="mb-3-m">не пиши слишком много текста в <br />абзац</li>

        </ul>

      </div>

      <div className="textpage__blocks mt-10-m mobile">

        <div className="textpage__liner relative" />

        <div className="flex mobile">
          
          <div className="montserrat text-16-m font-bold mt-20-m">2.</div>
          <div className="montserrat text-16-m font-bold mt-20-m ml-5-m">Если нужно написть много <br />текста под одним заголовком...</div>

        </div>

        <img src="/images/textpage/second-mob.jpg" className="textpage__img relative mt-40 mt-20-m mobile" />

        <ul className="montserrat text-14-m font-medium nowrap">

          <li className="mb-3-m">раздели его на несколько абзацев и <br />добавь для каждого отдельный </li>текстовый элемент

        </ul>

      </div>

      <div className="textpage__blocks mt-10-m mobile">

        <div className="textpage__liner relative" />

        <div className="flex mobile">
          
          <div className="montserrat text-16-m font-bold mt-20-m">3.</div>
          <div className="montserrat text-16-m font-bold mt-20-m ml-5-m">Используй цвет, <br />близкий к фону страницы</div>

        </div>

        <div className="montserrat text-14-m font-semibold mt-10-m ml-18-m nowrap">

          так будет смотреться гармоничнее. <br />
          Если фон страницы яркий, нужно <br />
          задать тексту полупрозрачный фон:

        </div>

        <img src="/images/textpage/third-mob.jpg" className="textpage__img relative mt-40 mt-20-m mobile" />

        <ol className="montserrat text-15-m font-semibold nowrap">

          <li className="montserrat mb-10-m ml-5-m">тут отображается выбранный <br />в палитре цвет</li>
          <li className="montserrat mb-10-m ml-5-m">область выбора оттенка цвета</li>
          <li className="montserrat mb-10-m ml-5-m">область выбора цвета</li>
          <li className="montserrat mb-10-m ml-5-m">
            прозрачность - если точка слева, <br />фон полностью невидимый, <br />если точка справа, фон полностью <br />видимый.
            Можно выбрать <br />промежуточное значение
          </li>

        </ol>

      </div>

      <div className="textpage__blocks mt-10-m mobile">

        <div className="textpage__liner relative" />

        <div className="flex mobile">
          
          <div className="montserrat text-16-m font-bold mt-20-m">4.</div>
          <div className="montserrat text-16-m font-bold mt-20-m ml-5-m">Если нужно перечислить <br />несколько пунктов</div>

        </div>

        <img src="/images/textpage/fourth-mob.jpg" className="textpage__img relative mt-40 mt-20-m mobile" />

        <ul className="montserrat text-16-m font-medium nowrap">

          <li className="mb-15-m">для каждого создай <br />текстовый элемент “обычный текст”</li>
          <li className="mb-5-m">особенно хорошо будет смотреться, <br />если перед текстом будет смайл <br />Emoji, соответствующий <br />смыслу текста</li>

        </ul>

      </div>

      <img src="/images/textpage/first.jpg" className="textpage__first relative mt-40 desktop" />
      <img src="/images/textpage/second.jpg" className="textpage__second relative mt-150 desktop" />
      <img src="/images/textpage/third.jpg" className="textpage__third relative mt-80 desktop" />
      <img src="/images/textpage/fourth.jpg" className="textpage__fourth relative mt-150 desktop" />
      <img src="/images/textpage/fifth.jpg" className="textpage__fifth relative mt-120 desktop" />

      <div className="textpage__button button_container flex flex-col-m mt-25-m mb-15-m">

        <Button

          url= { URLS.backgroundpage }
          text= {`Назад: Настройка фона страницы`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.addimages }
          text= {`Дальше: Добавление изображений`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default TextPage;