import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import "./howtostart.scss";


const HowToStart = () =>{

  

  return (

    <Container className='instructions'>
      
      <div className="text-47 font-bold text-orange">

        Как попасть <br className='mobile' />в онлайн-запись

      </div>

      <div className="instructions__row flex flex-col-reverse-m mt-38">

        <img className= 'instr1' src= "/images/instruction/instruction.jpg" />

        <div className="text_block mt-18">

          <div className="text-23 font-semibold mr-16-m">

            Изначально онлайн-запись не <br className='mobile' />активирована,<br className='desktop'/>
            потому что сперва <br className='mobile' />нужно настроить интернет-визитку.

          </div>

          <div className="text-23 font-semibold mt-150 mt-24-m mr-16-m">

            Чтобы открыть онлайн-запись, <br className='mobile' />нужно зайти<br className='desktop'/> 
            в конструктор визитки, <br className='mobile' />и нажать кнопку:

          </div>

          <div className="text-23 font-semibold mt-120 pl-15 desktop">

            После нажатия на эту кнопку, <br className='mobile' />откроется помощник<br className='desktop'/> 
            по настройке <br className='mobile' />онлайн записи

          </div>

        </div>

      </div>

      <div className="text-23 font-semibold mt-220 pl-15 mobile">

        После нажатия на эту кнопку, <br className='mobile' />откроется помощник<br className='desktop'/> 
        по настройке <br className='mobile' />онлайн записи

      </div>

      <div className="instructions__row flex flex-col-reverse-m mt-86">

        <div className="text_block mt-24">

          <div className="text-23 font-semibold text-right pr-57">

            Нажми на “шаг 1” и добавь услуги, <br className='mobile' />которые ты оказываешь<br /> 
            Процесс добавления услуги <br className='mobile' />подробно описан в разделе<br />
            <Link to="/#" className='link text-blue mt-7-m'> добавление услуги </Link>

          </div>

          <div className="text-23 font-semibold mt-40 text-right pr-57 ">

            Затем нажми  на “шаг 2” и настрой <br className='mobile' />свой рабочий график<br /><br /> 
            Процесс настройки графика <br className='mobile' />подробно описан в разделе<br /> 
            <Link to="/#" className='link text-blue mt-7-m'> настройка рабочего графика </Link>

          </div>

          <div className="text-23 font-semibold mt-120 text-right pr-57">

            После этих действий люди уже смогут <br /> 
            записываться к тебе на приём! 

          </div>

          <div className="text-23 font-semibold mt-45 text-right pr-57">

            Осталось только разместить ссылку <br /> 
            на свой Any Link у себя в соцсетях

          </div>

        </div>

        <img className = "instr2" src = "/images/instruction/instr2.jpg" />

      </div>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">

        <Button

          url= { URLS.preparing }
          text= {<>Назад: <br className='mobile'/>как работает онлайн-запись <span className="desktop pl-6">Any Link?</span></>}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.addingservice }
          text= {`Дальше:\nдобавление услуги`}
          className="orange ml-26 mt-12-m text-24 text-center-m"

        />
        
      </div>

    </Container>
  

  );

}

export default HowToStart;