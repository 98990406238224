import React from 'react';
import { Link } from "react-router-dom";

import './button.scss';

const Button = ({ text, url, className }) => {

  return (

    <Link to = { url }  className = {`button ${ className }`} onClick={() => { setTimeout(() => { window.scrollTo(0,0) }, 250) } }>

      { text }

    </Link>

  );

}
 
export default Button;