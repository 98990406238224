import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './payment.secret.scss';

const PaymentSecret = () => {

  return (

    <Container className="payment-contract flex flex-col">

      <div className="text-47 font-bold text-orange">

        Получение секретного <br className="mobile" />ключа

      </div>

      <div className="text-23 font-semibold mt-35 mt-20-m">

        После подписания договора в меню ЮKassa находим вкладку ОПЕРАЦИИ. Выбираем раздел “Платежи”. <br />
        <img src="/images/paymentsecret/1-mobile.jpg" className="payment-secret__img mt-25-m mb-25-m mobile"/>
        Появится окно истории платежей. В нижней части <br className="mobile" />жми кнопку “Создать счёт”

      </div>

      <img src="/images/paymentsecret/1-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/2-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-30 mt-25-m">

        После перехода на страницу “Ключи API” жми кнопку “Выпустить ключ”. Затем следуй инструкции ниже.
      
      </div>

      <img src="/images/paymentsecret/2-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/3-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="text-23 font-semibold text-right mt-40 mt-25-m desktop">

        1. В открывшемся меню укажи свой телефон и нажми кнопку “Получить код”

      </div>

      <div className="text-23 font-semibold mt-40 mt-25-m mobile">

        1. В открывшемся меню укажи свой телефон и нажми кнопку “Получить код”

      </div>

      <img src="/images/paymentsecret/3-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/4-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="text-23 font-semibold text-right mt-40 mt-25-m desktop">

        2. Введи полученный из смс код в окно. Если код не пришел - запроси его повторно

      </div>

      <div className="text-23 font-semibold mt-40 mt-25-m mobile">

        2. Введи полученный из смс код в окно. <br className="mobile" /><br className="mobile" />Если код не пришел - запроси его повторно

      </div>

      <img src="/images/paymentsecret/4-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/5-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="text-23 font-semibold text-right mt-40 mt-25-m desktop">

        3. Готово! Теперь скопируй и сохрани секретный ключ в надежном месте. Он понадобится для интеграции

      </div>

      <div className="text-23 font-semibold mt-40 mt-25-m mobile">

        3. Готово! Теперь скопируй и сохрани секретный ключ в надежном месте. <br className="mobile" /><br className="mobile" />Он понадобится для интеграции

      </div>

      <img src="/images/paymentsecret/5-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/6-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-35 mt-25-m">

        После в меню ЮKassa перейди на вкладку ИНТЕГРАЦИИ в раздел “HTTP-уведомления”.

      </div>

      <img src="/images/paymentsecret/6-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/7-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>
      <img src="/images/paymentsecret/8-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="text-23 font-semibold mt-35 mt-25-m">

        В разделе “URL для уведомлений” напиши адрес <span className="font-bold user-on">https://any.link/core/<br className="mobile" />payment_watcher_yookassa.php</span><br />
        <br className="mobile" />Выбери все события и нажми кнопку “Сохранить”

      </div>

      <img src="/images/paymentsecret/6-desktop.jpg" className="payment-secret__img mt-30 desktop"/>
      <img src="/images/paymentsecret/9-mobile.jpg" className="payment-secret__img mt-25-m mobile"/>

      <div className="button_container flex flex-col-m mt-100 mt-50-m mb-15-m">
        
        <Button

          url= { URLS.payment_contract }
          text= {`Назад:\nподписание договора`}
          className="blue text-24 text-center-m"

        />
      
        <Button

          url= { URLS.payment_prepay }
          text= {`Дальше:\nвключение предоплаты для услуги`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default PaymentSecret;