import React, { useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './handleclient.scss';

const HandleClient = () => {

  

  return (

    <Container className="handleclient">

      <div className="text-47 font-bold text-orange">

        Как записать <br className="mobile" />клиента вручную

      </div>

      <div className="handleclient__row flex flex-col-reverse-m">

        <div className="text_block mt-20">

          <div className="text-23 text-15-m font-semibold mr-16-m mt-15-m">

            Ты можешь записать клиента в свой график самостоятельно. <br /><br className="mobile" />
            Тогда на это время не сможет записаться другой человек. <br className="mobile" /><br className="mobile" />
            Это очень просто:<br className="mobile" /><br className="mobile" />

          </div>

          <div className="text-23 text-13-m font-semibold mt-20 mt-5-m mr-16-m">

            Зайди в свой график и нажми на “свободное время” в том временном промежутке, <br className="desktop" />
            в который нужно записать клиента:

          </div>

        </div>

      </div>

      <img className="handleclient__freetime mt-40 desktop" src="/images/handleclient/handle-freetime.jpg" />
      <img className="handleclient__freetime mt-15-m mobile" src="/images/handleclient/handle-freetime-mobile.jpg" />

      <div className="text-13-m font-semibold mt-15-m mr-16-m mobile">В появившемся окне выбери пункт <br />“записать клиента”</div>

      <img className="handleclient__clock mt-25-m mobile" src="/images/handleclient/handle-clock-mobile.jpg" />

      <div className="text-20-m font-bold text-center mt-35-m mr-16-m mobile">Заполни анкету <br /> бронирования</div>

      <img className="handleclient__sheet mt-60 desktop" src="/images/handleclient/handle-sheet.jpg" />
      <img className="handleclient__sheet mt-35-m mobile" src="/images/handleclient/handle-sheet-mobile.jpg" />

      <div className="text-15-m font-semibold mt-25-m mr-16-m mobile">

        Выбери время и услугу <br /><br />

      </div>

      <div className="text-13-m font-semibold text-blue mr-16-m mobile">

        Услуги сначала нужно добавить, если <br /> они еще не добавлены, прочитай раздел <br />“Добавление услуги” <br /><br />

      </div>

      <div className="text-13-m font-semibold mr-16-m mobile">

        Заполни данные клиента и обязательно <br /> нажми “подтвердить”, когда заполнишь <br />анкету

      </div>

      <div className="text-20-m font-bold text-center mt-35-m mr-16-m mobile">

        Готово!

      </div>

      <img className="handleclient__addtime mt-60 desktop" src="/images/handleclient/handle-addtime.jpg" />
      <img className="handleclient__addtime mt-35-m mobile" src="/images/handleclient/handle-addtime-mobile.jpg" />

      <div className="text-15-m font-semibold mt-20-m mr-16-m mobile">

        Время зарезервировано, его уже <br />
        не сможет занять никто другой

      </div>

      <div className="text-15-m font-semibold mt-15-m mr-16-m mobile">

        Запись о клиенте появилась <br />
        в твоём расписании <br />

      </div>

      <div className="button_container handleclient__button flex flex-col-m mt-100 mt-50-m mb-15-m">

        <Button

          url = { URLS.newclient }
          text = {`Назад:\nклиент записался - что делать`}
          className="blue text-24 text-center-m"

        />

        <Button
          url = { URLS.timegap }
          text = {`Дальше:\nкак "взять перерыв" среди дня`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default HandleClient;