import React, { Fragment, useEffect } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.aftersales.scss';

const ShopAfterSales = () => {

  useEffect(() => {

    if ( !window?.location?.pathname ) { return; }
    let anchor = document.getElementById( window.location.pathname.split('/')[ window.location.pathname.split('/').length -1 ] )
    if ( anchor ) { 
      setTimeout( () => { anchor.scrollIntoView() }, 300);
    }

  }, [ window?.location?.pathname ]);

  return (

    <Container className="shopaftersales flex flex-col">

      <a className="scrollAnchor" name="sale" id="shop_aftersales" />
      <div className="text-47 font-bold text-orange mt-35">

        У тебя купили товар. <br className="mobile" />Что дальше?

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-50 mt-25-m">

        Зайди в раздел “касса и выдача”. Как туда попасть, ты можешь узнать в предыдущей главе.

      </div>

      <img src="/images/shopaftersales/1-desktop.jpg" className="shopaftersales__first relative mobile mt-25-m" />

      <div className="flex mt-50 mt-20-m">

        <img src="/images/shopaftersales/1-desktop.jpg" className="shopaftersales__first relative desktop mt-25-m" />

        <div className="montserrat text-23 text-16-m font-semibold mt-40 ml-50 mt-20-m mt-minus">

          Кто-то оплатил заказ! <br className="mobile" /><br className="mobile" />
          Необходимо связаться с покупателем, <br className="desktop" />
          и договориться, <br className="mobile" />когда он сможет прийти и <br className="mobile" />забрать товар,
          <br className="desktop" /> или договориться <br className="mobile" />о доставке. <br />
          <br /><br className="desktop" />
          Нажми на заказ, чтобы увидеть подробную информацию

        </div>

        <img src="/images/shopaftersales/2-desktop.jpg" className="shopaftersales__second relative mobile mt-25-m" />

      </div>

      <div className="flex mt-50 mt-50-m">

        <img src="/images/shopaftersales/2-desktop.jpg" className="shopaftersales__second relative desktop mt-25-m" />

        <div className="montserrat text-23 text-16-m font-semibold mt-250 ml-50 mt-minus">

          Если нажать на номер телефона, откроется приложение <br />
          телефона, и можно будет сразу позвонить нажав на кнопку вызова   

        </div>

      </div>

      <a className="scrollAnchor" id="shop_aftersalesfix" />
      <div className="text-47 font-bold text-orange mt-80 mt-35-m">

        Как зафиксировать <br className="mobile" />выдачу товара?

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-65 mt-35-m">

        Если клиент пришёл за товаром <br className="mobile" />к тебе сам, попроси его назвать номер заказа и его номер телефона. <br /><br className="mobile" />
        Найди заказ с указанным номером, и сравни номер телефона, чтобы убедиться, что перед тобой <br />
        действительно человек, <br className="mobile" />который оплатил товар.

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-50 mt-25-m">

        Посмотри, какие товары находятся в заказе. Обрати внимание, что рядом с названием товара <br />
        пишется его количество: <br className="mobile" /><br className="mobile" />х1 значит один товар, <br className="mobile" />х2 значит два таких товара, и т.д.

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-50 mt-25-m">

        Собирая товары, нажимай на галочки справа от собранных товаров. Это удобно, <br />
        когда заказ большой, сразу видеть, какие товары осталось найти и выдать покупателю.

      </div>

      <img src="/images/shopaftersales/3-desktop.jpg" className="shopaftersales__third relative mobile mt-25-m" />

      <div className="flex mt-100 mt-minus">

        <div className="montserrat text-23 text-16-m font-semibold mt-250 ml-50 lefttext">

          После того, как заказ был выдан клиенту, <br className="desktop" />
          нажми кнопку <br className="mobile" />“выдать заказ”
          <br /> <br />
          Для этого галочки напротив всех товаров <br className="desktop" />
          в заказе должны быть поставлены!

        </div>

        <img src="/images/shopaftersales/3-desktop.jpg" className="shopaftersales__third relative desktop mt-25-m" />

      </div>

      <img src="/images/shopaftersales/4-desktop.jpg" className="shopaftersales__fourth relative mt-25-m mobile" />

      <div className="flex mt-150 mt-minus">

        <img src="/images/shopaftersales/4-desktop.jpg" className="shopaftersales__fourth relative desktop mr-100 mt-25-m" />

        <div className="montserrat text-23 text-16-m font-semibold mt-150 mt-5-m ml-100">

          В списке заказов этот заказ <br />
          приобретёт статус “выдан”

        </div>

      </div>

      <div className="shopaftersales__button button_container flex flex-col-reverse-m mt-25-m mb-15-m">

        <Button

          url= { URLS.shop_saleshystory }
          text= {<Fragment><span className="desktop">Назад: как посмотреть историю продаж</span><span className="mobile">Назад: <br className="mobile" />как посмотреть историю продаж</span></Fragment>}
          className="blue ml-26 text-24 mt-12-m text-center-m"

        />

        <div />

      </div>

    </Container>

  );

}

export default ShopAfterSales;