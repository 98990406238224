import React from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './contentplan.scss';

const ContentPlan = () => {

  return (

    <Container className="contentplan">

      <div className="text-47 font-bold text-orange">

        Создание <br className="mobile" />контент-плана страницы

      </div>

      <div className="contentplan__row flex flex-col-reverse-m">

        <div className="text_block mt-18">

          <div className="text-22 text-16-m font-semibold mr-16-m mt-20-m">

            Важно правильно расположить имеющуюся информацию на странице, чтобы человек который <br className="desktop" />
            её увидит, не пропустил важную информацию, увидел её в нужном порядке, и не замылил глаза.

          </div>

          <div className="text-22 font-semibold mr-16-m mt-40 mt-25-m">

            Поэтому ещё до настройки <br className="mobile" />Any Link мы сначала должны понять, как наилучшим образом <br />
            расположить информацию.

          </div>

        </div>

      </div>

      <div className="text-30 text-24-m font-bold mr-16-m mt-50 mt-35-m">

        Классическая схема:

      </div>

      <div className="contentplan__list mt-25 mt-15-m">

        <ol className="contentplan__list__text">

          <li className="desktop">Имя или название компании<span> (заголовок, по центру)</span></li>
          <li className="mobile">Ваше имя или <br />название компании<span> (заголовок, по центру)</span></li>
          <li>Краткое описание<span> (обычный текст, по центру)</span>, уникальное торговое предложение
          <span> (если имеется)</span> приветственное видео<span> (если имеется)</span></li>
          <li>Квадратные кнопки способов связи</li>
          <li>Подробная информация, но не стеной текста, а разбитая на маленькие группы заголовок + текст, 
          <br />если уместно - дополненная изображениями и видео</li>
          <li>Ответы на частые вопросы<span> (если есть контент для этого)</span></li>
          <li>Отзывы<span> (если есть контент для этого)</span> - в виде слайдера со скриншотами, или видео</li>
          <li>Местоположение на карте<span> (если есть адрес, и это необходимо)</span></li>
          <li>Имя или название компании<span> (заголовок, по центру)</span></li>

        </ol>

      </div>

      <div className="button_container flex flex-col-m mt-80 mb-25-m">

        <Button

          url= { URLS.landinfo }
          text= {`Назад: какая информация потребуется?`}
          className="blue text-24 text-center-m"

        />

        <Button

          url= { URLS.create_site }
          text= {`Дальше: Создание нового сайта`}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ContentPlan;