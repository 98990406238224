import React, { Fragment } from 'react';
import URLS from '../../../settings/urls';
import Button from '../../components/button';
import Container from '../../components/container';

import './shop.sales.scss';

const ShopSales = () => {  

  return (

    <Container className="shopsales flex flex-col">

      <div className="text-47 font-bold text-orange">

        Как продавать <br className="mobile" />товары через AnyLink

      </div>

      <div className="montserrat text-23 text-16-m font-semibold mt-30 mt-20-m">

        Ты можешь дополнительно зарабатывать, продавая какие либо свои товары или услуги  <br className="mobile" />через Any Link.<br />
        <br className="mobile" />Для посетителя твоей интернет-визитки выглядит это так:

      </div>

      <img src="/images/shopsales/1-desktop.jpg" className="shopsales__first relative mt-50 desktop" />
      <img src="/images/shopsales/1-mobile.jpg" className="shopsales__first relative mt-30-m mobile" />

      <div className="montserrat text-16-m font-semibold mt-25-m mobile">

        на твоей странице появляется <br />
        нижняя панель “Каталог товаров” <br />
        (этот заголовок можно изменить)

      </div>

      <div className="montserrat text-16-m font-semibold mt-20-m mobile">

        эта панель всегда видна <br />
        вне зависимости от того, какую <br />
        часть визитки просматривает посетитель

      </div>

      <div className="montserrat text-16-m font-semibold mt-20-m mobile">

        при нажатии на зелёную стрелку <br />
        открывается перечень товаров

      </div>

      <div className="montserrat text-23 text-16-m text-left font-semibold mt-130 desktop">

        Товары можно группировать по категориям, <br />а также есть поиск по названию товара

      </div>
      
      <img src="/images/shopsales/2-desktop.jpg" className="shopsales__second relative" />

      <div className="montserrat text-23 text-16-m font-semibold mt-50-m ml-5-m mobile">

        Товары можно группировать <br />по категориям, а также есть  <br className="mobile" />поиск по названию товара

      </div>

      <div className="montserrat text-23 text-16-m textright font-semibold mt-15-m ml-5-m">

        При нажатии на товар можно увидеть его развёрнутое <br />
        описание, а также добавить  <br className="mobile" />в корзину, чтобы заказать <br />
        несколько товаров сразу

      </div>

      <img src="/images/shopsales/3-desktop.jpg" className="shopsales__third relative" />

      <div className="montserrat text-23 text-16-m text-left textdown font-semibold mt-5-m">

        Более того, можно задать <br className="mobile" />вопрос о товаре <br className="desktop" />
        перед покупкой. <br className="mobile" /><br className="mobile" />Клиент оставляет все <br className="desktop" />
        свои <br className="mobile" />контактные данные вместе <br className="mobile" />с вопросом

      </div>

      <img src="/images/shopsales/4-desktop.jpg" className="shopsales__fourth relative" />

      <div className="montserrat text-23 text-16-m text-left textup font-semibold">

        Через Any Link можно принимать онлайн-оплату за товары. <br className="mobile" /><br className="mobile" />Так как же включить <br className="mobile" />функционал <br className="desktop" />
        интернет-магазина? <br className="mobile" /><br className="mobile" />Это мы рассмотрим в следующей главе.

      </div>

      <div className="shopsales__button button_container flex flex-col-m mt-25-m mb-15-m">

        <div />

        <Button

          url= { URLS.shop_open }
          text= { <Fragment><span className="desktop"> Дальше: как включить себе интернет-магазин</span> <span className="mobile">Дальше: <br />как включить интернет-магазин</span></Fragment>}
          className="orange ml-26 text-24 mt-12-m text-center-m"

        />

      </div>

    </Container>

  );

}

export default ShopSales;